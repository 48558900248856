import ROUTES from 'constants/routes'

import { historicalDiveRecordVerificationRoute } from 'routes/AuthRoutes/AuthRoutes'

import { FullScreenLayout, NoAuthLayout } from 'components/layouts'
import { NotFoundPage } from 'screens/common'
import ForgotPassword from 'screens/noAuth/ForgotPassword'
import Login from 'screens/noAuth/Login'
import Register from 'screens/noAuth/Register'
import RegisterPassword from 'screens/noAuth/RegisterPassword'
import ResetPassword from 'screens/noAuth/ResetPassword'
import { DeclineEmailChange } from 'screens/diver/settings'

const noAuthRoutes = [
    {
        element: <NoAuthLayout />,
        layout: true,
        routes: [
            {
                path: ROUTES.HOME,
                element: <Login />,
            },
            {
                path: ROUTES.LOGIN,
                element: <Login />,
            },
            {
                path: ROUTES.REGISTER,
                element: <Register />,
            },
            {
                path: `${ROUTES.REGISTER_PASSWORD}/:token`,
                element: <RegisterPassword />,
            },
            {
                path: ROUTES.FORGOT_PASSWORD,
                element: <ForgotPassword />,
            },
            {
                path: `${ROUTES.RESET_PASSWORD}/:token`,
                element: <ResetPassword />,
            },
            {
                path: ROUTES.NOT_FOUND_PAGE,
                element: <NotFoundPage />,
            },
        ],
    },
    {
        element: <FullScreenLayout />,
        layout: true,
        routes: [
            {
                ...historicalDiveRecordVerificationRoute,
            },
            {
                path: `${ROUTES.DECLINE_EMAIL_CHANGE}/:hash`,
                element: <DeclineEmailChange />,
            },
        ],
    },
]

export { noAuthRoutes }
