const formatByName = (items) => {
    return items?.map(({ name }) => `${name}`)
}

const formatByNumberOfPills = (items, count = 5) => {
    if (items.length <= count) {
        return items
    }

    return [...items.slice(0, count), `+${items.length - count}`]
}

export { formatByName, formatByNumberOfPills }
