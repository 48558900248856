import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    MOMENT_FORMATS,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
    isDateAfter,
    isDateBefore,
} from 'services/moment.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { ALERT_TYPES, FILE_UPLOAD_TYPE, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import {
    CheckboxField,
    DateTimeField,
    FileUploadField,
    InputField,
    SelectField,
    TextAreaField,
} from 'components/formFields'
import Note from 'components/Note'

const PastEmploymentForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const requiredMessage = t('form.error.required')

    const initialValues = {
        employer: initialData?.employer ?? null,
        location: initialData?.location ?? '',
        contactPerson: initialData?.contactPerson ?? '',
        contactEmail: initialData?.contactEmail ?? '',
        contactPhone: initialData?.contactPhone ?? '',
        pastEmploymentPosition: initialData?.pastEmploymentPosition ?? null,
        industry: initialData?.industry ?? null,
        startedDate: initialData?.startedDate ?? '',
        finishedDate: initialData?.finishedDate ?? null,
        stillEmployed: initialData?.stillEmployed ?? false,
        jobDuties: initialData?.jobDuties ?? '',
        files: initialData?.files ?? [],
    }

    const validation = Yup.object({
        employer: Yup.object().required(requiredMessage),
        location: Yup.string().trim().required(requiredMessage),
        pastEmploymentPosition: Yup.object().required(requiredMessage),
        industry: Yup.object().required(requiredMessage),
        startedDate: Yup.date()
            .test('startedDate', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.startedDate'),
                    path: 'startedDate',
                })
            })
            .required(requiredMessage),
        finishedDate: Yup.string().when('stillEmployed', {
            is: (val) => val === true,
            then: () => Yup.date().notRequired(),
            otherwise: () =>
                Yup.date()
                    .test('finishedDate', (value, { parent, createError }) => {
                        if (
                            isDateBefore(
                                value,
                                parent.startedDate,
                                MOMENT_FORMATS.DATE
                            ) ||
                            isDateAfter(
                                value,
                                getTodaysDate(),
                                MOMENT_FORMATS.DATE
                            )
                        ) {
                            return createError({
                                message: t('form.error.invalidDate'),
                                path: 'finishedDate',
                            })
                        }
                        return true
                    })
                    .required(requiredMessage),
        }),
        jobDuties: Yup.string()
            .max(500, t('form.error.maxCharsLimit'))
            .trim()
            .required(requiredMessage),
        stillEmployed: Yup.boolean().required(requiredMessage),
        files: Yup.array(),
    })

    const onSubmit = async ({ ...formData }) => {
        try {
            await handleSubmit({
                ...formData,
                user: { id: userId, entityType: ENTITIES.USER },
            })
            handleClose()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({
                values: { stillEmployed, startedDate },
                setFieldValue,
                isSubmitting,
            }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title={title || ''}
                        isSubmitting={isSubmitting}
                        buttons={{
                            nextBtn: {
                                label: 'button.savePastEmployment',
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className="_w">
                                {/* NOTE:: test after BE implementation of create new */}
                                <div className="_12 _m6">
                                    <SelectField
                                        name="employer"
                                        label="form.label.employer"
                                        entityType={ENTITIES.EMPLOYER}
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="location"
                                        label="form.label.location"
                                        placeholder="form.placeholder.location"
                                        icon={ICONS.LOCATION}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        size={ICON_SIZE.SIZE20}
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="contactPerson"
                                        label="form.label.contactPerson"
                                        placeholder="form.placeholder.contactPerson"
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="contactEmail"
                                        label="form.label.contactEmail"
                                        placeholder="form.placeholder.contactEmail"
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="contactPhone"
                                        label="form.label.contactPhone"
                                        placeholder="form.placeholder.contactPhone"
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                {/* NOTE:: test after BE implementation of create new */}
                                <div className="_12 _m6">
                                    <SelectField
                                        name="pastEmploymentPosition"
                                        placeholder="form.placeholder.diverPosition"
                                        label="form.label.position"
                                        entityType={
                                            ENTITIES.PAST_EMPLOYMENT_POSITION
                                        }
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <SelectField
                                        name="industry"
                                        label="form.label.industry"
                                        entityType={ENTITIES.INDUSTRY}
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                            </div>

                            <div className="_w -mt15">
                                <div className="_12 _m4">
                                    <DateTimeField
                                        name="startedDate"
                                        label="form.label.startedDate"
                                        required
                                        maxDate={getTodaysDate()}
                                        dateFormat="MMM/y"
                                        monthPicker
                                    />
                                </div>
                                <div className="_12 _s8 _m4">
                                    <DateTimeField
                                        name="finishedDate"
                                        label="form.label.finishedDate"
                                        required
                                        disabled={stillEmployed === true}
                                        minDate={new Date(startedDate)}
                                        maxDate={getTodaysDate()}
                                        dateFormat="MMM/y"
                                        monthPicker
                                    />
                                </div>
                                <div className="_12 _s4 centered-checkbox -withSelect">
                                    <CheckboxField
                                        name="stillEmployed"
                                        label="form.label.stillEmployed"
                                        placeholder="form.label.stillEmployed"
                                        translate
                                        onChange={(e) => {
                                            const newValue = e.target.checked
                                            setFieldValue(
                                                'stillEmployed',
                                                newValue
                                            )
                                            if (newValue === true) {
                                                setFieldValue(
                                                    'finishedDate',
                                                    null
                                                )
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <TextAreaField
                                        name="jobDuties"
                                        label="form.label.jobDuties"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="_w -pb20">
                                <Separator />
                                <FileUploadField
                                    name="files"
                                    label="form.label.pastEmploymentUploadFiles"
                                    type={FILE_UPLOAD_TYPE.FILE}
                                />
                            </div>
                            <Note note="message.verificationOfEmployment" />
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default PastEmploymentForm
