import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router-dom'

import { BASE_FILE_URL } from 'utils/axiosClient'

import COLORS from 'constants/colors'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import { ICON_SIZE, INFO_VALUE_TYPE } from 'constants/enums'

import Icon from 'components/Icon'
import Signature from './Signature'

const Info = ({
    label,
    value,
    color,
    column,
    translate,
    separator,
    icon,
    iconName,
    companyID,
    valueType,
    borderRadius,
    verifiedUser,
    withBorder,
    withSpaceBetween,
    textColor,
    descriptionTextWithoutLimit,
    descriptionText,
    boxWithSeparators,
    onlyInOneLine,
}) => {
    const t = useTranslate()

    const valueContent = (item) => {
        return (
            <Fragment>
                {icon && (
                    <Icon
                        name={iconName}
                        color={COLORS.DARK_BLUE_40}
                        size={ICON_SIZE.SIZE20}
                    />
                )}
                {separator && item !== value[value.length - 1]
                    ? item + ' / '
                    : item}
            </Fragment>
        )
    }

    const renderValue = (item) => {
        switch (valueType) {
            case INFO_VALUE_TYPE.CONTRACTOR_LINK:
                return (
                    <Link
                        className={`a-link aligned-center justify-center -value -valueWithoutColor ${
                            icon ? '-icon' : ''
                        }`}
                        to={`${ROUTES.DIVING_CONTRACTOR_PROFILE}/${companyID}`}
                    >
                        {valueContent(item)}
                    </Link>
                )
            case INFO_VALUE_TYPE.DIVER_PROFILE_LINK:
                return (
                    <div className="a-teamMemberWithImage">
                        <Link
                            className="a-link aligned-center justify-center -member a-mediumTextSemiBold"
                            to={ROUTES.DIVER_PROFILE + `/${item.profileHash}`}
                        >
                            {item.fullName}
                            {(item.identityVerified || verifiedUser) && (
                                <span className="verifiedIcon">
                                    <Icon name={ICONS.VERIFICATION_PROFILE} />
                                </span>
                            )}
                            {item.avatarPath && (
                                <div className="-memberImage -image">
                                    <img
                                        src={BASE_FILE_URL + item.avatarPath}
                                        alt="banner-image"
                                    />
                                </div>
                            )}
                            <div className="-memberImage -placeholder">
                                <Icon
                                    name={ICONS.USER}
                                    color={COLORS.WHITE}
                                    size={ICON_SIZE.SIZE18}
                                />
                            </div>
                        </Link>
                    </div>
                )
            case INFO_VALUE_TYPE.CERTIFICATE_URL:
                return (
                    <Link
                        className="a-mediumTextSemiBold"
                        to={item?.startsWith('http') ? item : '//' + item}
                        target="blank"
                        title={item}
                    >
                        {item}
                    </Link>
                )
            case INFO_VALUE_TYPE.SIGNATURE:
                return <Signature value={item} preview />
            default:
                return (
                    <div
                        className={`-value ${
                            color
                                ? '-valueWithColor a-status'
                                : '-valueWithoutColor'
                        } -${color || ''} ${icon ? '-icon' : ''} ${
                            borderRadius ? '-borderRadius' : ''
                        } -textColor-${textColor || 'gray'} ${
                            descriptionText
                                ? 'a-mediumText a-lightText -opacity-60 -descriptionText'
                                : ''
                        }`}
                    >
                        {valueContent(item)}
                    </div>
                )
        }
    }

    return (
        <div
            className={`-info ${color ? '-withColor' : ''} ${
                column ? '-column' : '-row'
            } ${iconName ? '-withIcon' : ''} ${
                withBorder ? '-withBorder' : ''
            } ${withSpaceBetween ? '-withSpaceBetween' : ''} ${
                descriptionTextWithoutLimit
                    ? '-descriptionTextWithoutLimit'
                    : ''
            } ${descriptionText ? '-descriptionText' : ''}
             ${boxWithSeparators ? '-boxWithSeparators' : ''}
             ${onlyInOneLine ? '-onlyInOneLine' : ''}`}
        >
            {label && (
                <span className="-label a-mediumText a-lightText">
                    {translate ? label : t(label)}:
                </span>
            )}
            {iconName && (
                <Icon
                    name={iconName}
                    size={ICON_SIZE.SIZE18}
                    color={COLORS.DARK_BLUE_60}
                />
            )}
            {Array.isArray(value) ? (
                <div className="-values">
                    {value.map((item, index) => {
                        return (
                            <Fragment key={index}>{renderValue(item)}</Fragment>
                        )
                    })}
                </div>
            ) : (
                renderValue(value)
            )}
        </div>
    )
}

Info.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
        PropTypes.object,
    ]),
    color: PropTypes.oneOf([
        'gray',
        'red',
        'green',
        'orange',
        'purple',
        'blue',
        'darkYellow',
        'pink',
        'grayLight',
    ]),
    textColor: PropTypes.oneOf([
        'gray',
        'red',
        'green',
        'orange',
        'purple',
        'blue',
    ]),
    column: PropTypes.bool,
    translate: PropTypes.bool,
    separator: PropTypes.bool,
    link: PropTypes.bool,
    valueType: PropTypes.string,
    borderRadius: PropTypes.bool,
    verifiedUser: PropTypes.bool,
    withBorder: PropTypes.bool,
    withSpaceBetween: PropTypes.bool,
    descriptionTextWithoutLimit: PropTypes.bool,
    descriptionText: PropTypes.bool,
    onlyInOneLine: PropTypes.bool,
}

Info.defaultProps = {
    column: false,
    translate: false,
    link: false,
    valueType: INFO_VALUE_TYPE.INFO_TYPE,
    borderRadius: false,
    verifiedUser: false,
    withBorder: false,
    withSpaceBetween: false,
    descriptionTextWithoutLimit: false,
    descriptionText: false,
    onlyInOneLine: false,
}

export default Info
