import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import DateTimePicker from 'react-datetime-picker'
import moment from 'moment'

import { MOMENT_FORMATS } from 'services/moment.service'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import clickOutsideHOC from './wrappers/clickOutsideHOC'
import FieldLabel from './FieldLabel'
import FieldError from './FieldError'
import TimePicker from './TimePicker'
import Icon from './Icon'

import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'

const calendarProps = {
    prevLabel: <Icon name={ICONS.LEFT_ARROW} />,
    prev2Label: <Icon name={ICONS.LEFT_DOUBLE_ARROW} />,
    nextLabel: <Icon name={ICONS.RIGHT_ARROW} />,
    next2Label: <Icon name={ICONS.RIGHT_DOUBLE_ARROW} />,
}

const dateTimeInputClasses = [
    'react-datetime-picker__inputGroup',
    'react-datetime-picker__inputGroup__input',
]

const DateTime = ({
    open: showTimePicker,
    setOpen: setShowTimePicker,
    name,
    label,
    value,
    setValue,
    setTouched,
    valueFormat,
    dateFormat,
    required,
    disabled,
    showLabel,
    error,
    monthPicker,
    yearPicker,
    timePicker,
    maxDate,
    minDate,
    dropup,
}) => {
    const t = useTranslate()

    const [showCalendar, setShowCalendar] = useState(false)

    const handleChange = (selectedValue) => {
        if (!selectedValue && !value) {
            return
        }

        if (selectedValue && moment(selectedValue).format('YYYY').length > 4) {
            return
        }
        const formattedValue = selectedValue
            ? moment(selectedValue).format(valueFormat)
            : ''
        setValue(formattedValue)
    }

    const handleTimeInputChange = (event) => {
        if (timePicker && !value && event.target.value) {
            let time
            if (event.target.name === 'hour24') {
                time = moment()
                    .set('hour', event.target.value)
                    .set('minute', 0)
                    .set('second', 0)
            } else if (event.target.name === 'minute') {
                time = moment()
                    .set('hour', 0)
                    .set('minute', event.target.value)
                    .set('second', 0)
            }

            if (time) {
                handleChange(time)
            }
        }
    }

    const handleTouched = () => {
        if (setTouched) setTouched(true)
    }

    const formatValue = () => {
        return value ? moment(value, valueFormat)._d : value
    }

    const closeTimePicker = () => {
        setShowTimePicker(false)
        if (timePicker) handleTouched()
    }

    const openTimePicker = () => {
        if (disabled) return
        setShowTimePicker(true)
    }

    const toggleTimePicker = () => {
        if (disabled) return
        if (showTimePicker) handleTouched()
        setShowTimePicker(!showTimePicker)
    }

    const openCalendar = () => {
        closeTimePicker()
        setShowCalendar(true)
    }

    const closeCalendar = () => {
        setShowCalendar(false)
        if (!timePicker) handleTouched()
    }

    const openPicker = (e) => {
        if (disabled) return

        if (
            Object.values(e.target.classList).some((className) =>
                dateTimeInputClasses.includes(className)
            )
        ) {
            if (timePicker) {
                setShowTimePicker(true)
            } else {
                setShowCalendar(true)
            }
        }
    }

    const getCalendarPickerType = () => {
        if (monthPicker) return 'year'
        if (yearPicker) return 'decade'
        return 'month'
    }

    const selectedMonth = value ? moment(value, valueFormat).format('MMM') : ''

    return (
        <div
            className={`m-dateTime ${timePicker ? '-timePicker' : ''} ${
                error && !disabled ? '-error' : ''
            } ${disabled ? '-disabled' : ''} ${!value ? '-empty' : ''} ${
                selectedMonth ? `-${selectedMonth.toLowerCase()}` : ''
            } ${dropup ? '-dropup' : ''}`}
        >
            {showLabel && (
                <FieldLabel
                    label={label || `form.label.${name}`}
                    required={required}
                />
            )}
            <DateTimePicker
                name={name}
                value={formatValue()}
                onChange={handleChange}
                onMouseDown={openPicker}
                onCalendarOpen={openCalendar}
                onCalendarClose={closeCalendar}
                onClockOpen={openTimePicker}
                onInput={handleTimeInputChange}
                isCalendarOpen={showCalendar}
                disableClock={true}
                disableCalendar={timePicker}
                calendarIcon={
                    <Icon
                        name={ICONS.CALENDAR}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_60}
                    />
                }
                clearIcon={
                    value ? (
                        <div
                            onClick={closeTimePicker}
                            title={t('general.clearDate')}
                        >
                            <Icon
                                name={ICONS.CLEAR}
                                size={ICON_SIZE.SIZE20}
                                color={COLORS.DARK_BLUE}
                                title={t('button.clearSelection')}
                            />
                        </div>
                    ) : (
                        false
                    )
                }
                disabled={disabled}
                format={dateFormat}
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
                hourPlaceholder="HH"
                minutePlaceholder="mm"
                locale="en"
                calendarProps={{
                    maxDetail: getCalendarPickerType(),
                    maxDate: maxDate,
                    minDate: minDate,
                    ...calendarProps,
                }}
            />
            {timePicker && (
                <div className="timePickerIcon" onClick={toggleTimePicker}>
                    <Icon
                        name={ICONS.CLOCK}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_60}
                    />
                </div>
            )}
            {showTimePicker && (
                <TimePicker
                    name={name}
                    value={value}
                    valueFormat={valueFormat}
                    handleChange={handleChange}
                />
            )}
            {error && !disabled && <FieldError error={error} />}
        </div>
    )
}

export const DateTimeMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    // This format is sent to the API
    valueFormat: PropTypes.string,
    // Supported formats by the library react-datetime-picker
    dateFormat: PropTypes.oneOf([
        'd/MMM/y',
        'MMM/y',
        'd/MMM/y HH:mm:ss',
        'd/MMM/y HH:mm',
        'HH:mm',
    ]),
    showLabel: PropTypes.bool,
    monthPicker: PropTypes.bool,
    yearPicker: PropTypes.bool,
    timePicker: PropTypes.bool,
}

DateTime.propTypes = {
    ...DateTimeMainPropTypes,
    value: PropTypes.any,
    setValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    dropup: PropTypes.bool,
}

DateTime.defaultProps = {
    valueFormat: MOMENT_FORMATS.DATE_API,
    dateFormat: 'd/MMM/y',
    showLabel: true,
    monthPicker: false,
    yearPicker: false,
    timePicker: false,
    dropup: false,
}

export default clickOutsideHOC(DateTime)
