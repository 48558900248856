import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ProfileContext } from 'screens/diver/profile/context/ProfileContext'

import { editEntityService } from 'services/entity.service'
import {
    MOMENT_FORMATS,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
} from 'services/moment.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    ICON_SIZE,
    INPUT_FILED_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import REGEXP from 'constants/regex'
import ROUTES from 'constants/routes'

import Modal from 'components/Modal'
import Separator from 'components/Separator'
import StepButtons from 'components/StepButtons'
import {
    CheckboxField,
    MultiselectField,
    ProfilePictureUploadField,
    TextAreaField,
} from 'components/formFields'
import CheckboxGroupField from 'components/formFields/CheckboxGroupField'
import DateTimeField from 'components/formFields/DateTimeField'
import InputField from 'components/formFields/InputField'
import SelectField from 'components/formFields/SelectField'
import SelectGroupField from 'components/formFields/SelectGroupField'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import Note from 'components/Note'

const PersonalInfoModalContext = ({ setAvailability }) => {
    const formikContext = useFormikContext()

    const { values } = formikContext

    const { userStatus } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }

        if (!firstRender.current) {
            setAvailability(userStatus?.name)
        }
    }, [userStatus])
}

const PersonalInfoModal = ({ setOpen, open }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()
    const { profileHash } = useParams()

    const { currentUser, userId, fetchCurrentUser, setCurrentUser } =
        useContext(CurrentUserContext)
    const { profile, fetchProfile } = useContext(ProfileContext)
    const [availability, setAvailability] = useState(profile?.userStatus)

    const { setAlert } = useContext(AlertContext)

    // It is necessary to clear the location state
    // (that is sent from the homepage due to completing the profile) after the component is rendered
    useEffect(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true })
    }, [])

    const initialValues = {
        firstName: currentUser.firstName ?? '',
        middleName: currentUser.middleName ?? '',
        lastName: currentUser.lastName ?? '',
        currentTitle: currentUser.currentTitle ?? '',
        dateOfBirth: currentUser.dateOfBirth ?? '',
        hidden: currentUser.hidden ?? false,
        country: currentUser.country ?? null,
        nationality: currentUser.nationality ?? '',
        diverLanguages: currentUser.diverLanguages ?? [],
        email: currentUser.email ?? '',
        countryPhoneCode: currentUser.countryPhoneCode ?? null,
        phoneNumber: currentUser.phoneNumber ?? '',
        diverPositions: currentUser.diverPositions ?? [],
        about: currentUser.about ?? '',
        professionalSummary: currentUser.professionalSummary ?? '',
        regions: currentUser.regions ?? [],
        userStatus: currentUser.userStatus ?? '',
        avatar: currentUser.avatar ?? null,
    }

    const requiredMessage = t('form.error.required')
    const maximumCharacters = `${t('form.error.maximumAllowed')} 500 ${t(
        'form.error.characters'
    )}`

    const validation = Yup.object({
        firstName: Yup.string().trim().required(requiredMessage),
        middleName: Yup.string().trim(),
        lastName: Yup.string().trim().required(requiredMessage),
        dateOfBirth: Yup.date()
            .test('dateOfBirth', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)
                ) {
                    return true
                }
                return createError({
                    message: t('form.error.birthDate'),
                    path: 'dateOfBirth',
                })
            })
            .required(requiredMessage),
        country: Yup.object().required(requiredMessage),
        nationality: Yup.string().trim(),
        diverLanguages: Yup.array()
            .min(1, t('form.error.atLeastOneLanguage'))
            .required(requiredMessage),
        diverPositions: Yup.array()
            .min(1, t('form.error.atLeastOneRole'))
            .required(requiredMessage),
        email: Yup.string()
            .email(t('form.error.invalidEmail'))
            .required(requiredMessage),
        regions: Yup.array()
            .min(1, t('form.error.addAtLeastOne'))
            .required(requiredMessage),
        userStatus: Yup.object().required(requiredMessage),
        about: Yup.string().max(500, maximumCharacters),
    })

    const handleSubmit = async (
        { diverLanguages, ...formData },
        { setSubmitting }
    ) => {
        try {
            setSubmitting(true)
            const response = await editEntityService(
                ENTITIES.USER,
                userId,
                {
                    ...formData,
                    diverLanguages: diverLanguages.map((item) => ({
                        ...item,
                        user: {
                            id: userId,
                            entityType: ENTITIES.USER,
                        },
                    })),
                },
                true,
                ['diverLanguages']
            )
            fetchCurrentUser()
            if (profileHash === response.data.profileHash) {
                fetchProfile({ profileHash: response.data.profileHash })
            } else {
                setCurrentUser((state) => ({ ...state, profileHash: response.data.profileHash }))
                navigate(`${ROUTES.DIVER_PROFILE}/${response.data.profileHash}`)
            }
            setOpen(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting }) => (
                <Form className="_wr personalInfoModal">
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        type="personalInformation"
                        title="general.editPersonalInformation"
                        isSubmitting={isSubmitting}
                        buttons={{
                            nextBtn: {
                                label: 'button.savePersonalInformation',
                                handleClick: handleSubmit,
                            },
                        }}
                    >
                        <PersonalInfoModalContext
                            setAvailability={setAvailability}
                        />
                        <div className="-contentElements">
                            <div className="_w">
                                <ProfilePictureUploadField
                                    name="avatar"
                                    label="form.label.avatarPath"
                                    multipleUpload={false}
                                    profilePage={true}
                                    userStatus={profile?.userStatus}
                                    availability={availability}
                                />
                                <div className="_12 _m9 -mainInfo">
                                    <div className="_w">
                                        <div className="_12 _m6">
                                            <SelectField
                                                name="userStatus"
                                                entityType={
                                                    ENTITIES.USER_STATUSES
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.OBJECT
                                                }
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="_w">
                                        <div className="_12 _m6 centered-checkbox">
                                            <CheckboxField
                                                name="hidden"
                                                label="form.label.hidden"
                                                title={
                                                    'general.profileVisibility'
                                                }
                                                tooltip={
                                                    'form.label.hiddenUserText'
                                                }
                                                icon={ICONS.HELP_AND_INFO}
                                                translate
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <InputField name="firstName" required />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="middleName" />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="lastName" required />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="currentTitle"
                                        label="form.label.title"
                                        placeholder="form.placeholder.title"
                                    />
                                </div>
                                <div className="_12 _m6 calendarMovedToRight">
                                    <DateTimeField
                                        name="dateOfBirth"
                                        icon={true}
                                        required
                                        maxDate={getTodaysDate()}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <CheckboxGroupField
                                        name="diverPositions"
                                        label="form.label.selectYourRoles"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w ">
                                <Separator />
                                <div className="_12 _m6">
                                    <SelectField
                                        name="country"
                                        label="form.label.countryOfResidence"
                                        placeholder="form.placeholder.countryOfResidence"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="nationality" />
                                </div>
                            </div>
                            <div className="_w ">
                                <Separator />
                                <div className="_12 _l11 oneLineSelectGroup">
                                    <SelectGroupField
                                        name="diverLanguages"
                                        title="general.addLanguages"
                                        description="general.pleaseAddAllLanguages"
                                        fieldsName={[
                                            'language',
                                            'languageProficiency',
                                        ]}
                                        fieldsPlaceholder={[
                                            'form.placeholder.selectLanguage',
                                            'form.placeholder.selectProficiency',
                                        ]}
                                        fieldsEntityType={[
                                            ENTITIES.LANGUAGE,
                                            ENTITIES.LANGUAGE_PROFICIENCY,
                                        ]}
                                        fieldsRequired={[true, true]}
                                        fieldsShowLabel={[false, false]}
                                        required
                                        fieldsSearchable={[true, true]}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <Note note="notes.changeEmailAndPhoneNumber" />
                                </div>
                                <div className="_12 _l6">
                                    <InputField
                                        name="email"
                                        label="form.label.profileEmail"
                                        placeholder="form.placeholder.profileEmail"
                                        type={INPUT_FILED_TYPE.EMAIL}
                                        icon={ICONS.MAIL}
                                        size={ICON_SIZE.SIZE20}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        disabled
                                        required
                                    />
                                </div>
                                <div className="_12 _l6">
                                    <div className="-phoneNumber fakeLabel -notFirst">
                                        <SelectField
                                            name="countryPhoneCode"
                                            label="form.label.phone"
                                            entityType={ENTITIES.COUNTRY}
                                            displayAttribute="callingCode"
                                            searchAttribute="callingCode"
                                            iconAttribute="flag"
                                            showPlaceholder={false}
                                            params={{ sort: 'callingCode' }}
                                            searchable
                                            disabled
                                        />
                                        <InputField
                                            name="phoneNumber"
                                            label="general.fakeLabel"
                                            placeholder="form.placeholder.phone"
                                            icon={ICONS.PHONE}
                                            iconColor={COLORS.DARK_BLUE_60}
                                            size={ICON_SIZE.SIZE20}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <TextAreaField
                                        name="about"
                                        label="form.label.aboutMe"
                                        placeholder="form.placeholder.aboutMe"
                                        largeTextarea
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <TextAreaField
                                        name="professionalSummary"
                                        label="form.label.professionalSummary"
                                        placeholder="form.placeholder.professionalSummary"
                                        largeTextarea
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="regions"
                                        label="form.label.regions"
                                        placeholder="form.placeholder.regions"
                                        entityType={ENTITIES.REGIONS}
                                        searchable
                                        required
                                        dropup
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

PersonalInfoModal.propTypes = {
    setOpen: PropTypes.func,
    open: PropTypes.bool,
}

export default PersonalInfoModal
