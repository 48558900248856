import { Fragment, useContext } from 'react'
import { useParams } from 'react-router-dom'

import useFetchDataById from 'hooks/useFetchDataById'

import { DiveRecordContext } from './contexts/DiveRecordContext'

import ENTITIES from 'constants/entities'

import StepButtons from 'components/StepButtons'
import GeneralInformationSummary from './components/generalInformation/GeneralInformationSummary'

const GeneralInformationVerification = () => {
    const { diveRecordId } = useParams()

    const { diveRecord, goToNextStep, goToProjectPage } =
        useContext(DiveRecordContext)

    const generalInformationId =
        diveRecord.data?.diveRecordGeneralInformation?.id

    const { data: generalInformationData } = useFetchDataById(
        ENTITIES.GENERAL_INFORMATION_RECORD,
        generalInformationId,
        {
            include:
                'bodyOfWater,region,bottomConditions,country,divingMode,divingPlatform,industry,locationType,waterQualities,supervisor,divePhysicalCharacteristics,projectIndustryType',
        },
        !!generalInformationId && !!diveRecordId,
        generalInformationId
    )

    const data = diveRecordId
        ? generalInformationData
        : diveRecord.data?.diveRecordGeneralInformation

    if (!generalInformationId || !data) return null

    return (
        <Fragment>
            <div className="ofs_l2 _l8 -diveRecordContent">
                <GeneralInformationSummary
                    data={data}
                    diveRecordId={
                        diveRecordId
                            ? diveRecord.data?.id
                            : diveRecord.data?._id
                    }
                    unitImperial={diveRecord.data?.unitImperial}
                    diveRecordType={diveRecord.data.diveRecordType}
                    verification
                />
            </div>
            <StepButtons
                backlabel="button.cancel"
                handleBack={goToProjectPage}
                handleNext={goToNextStep}
                canSkip={false}
            />
        </Fragment>
    )
}

export default GeneralInformationVerification
