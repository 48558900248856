/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import _ from 'lodash'

import { createEntityService } from 'services/entity.service'

import useFetchData from 'hooks/useFetchData'

import { isFormDisabled } from 'utils/form'
import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES, ICON_SIZE, SELECT_VALUE_TYPE } from 'constants/enums'
import { SEARCH_DELAY } from 'constants/constants'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Dropdown from 'components/Dropdown'
import FieldError from 'components/FieldError'
import FieldLabel from 'components/FieldLabel'
import Icon from './Icon'

const Multiselect = ({
    name,
    open,
    setOpen,
    outsideClick,
    label,
    placeholder,
    value,
    setValue,
    setTouched,
    entityType,
    params,
    searchable,
    required,
    defaultOptions,
    reload,
    condition,
    error,
    isJSONAPI,
    apiUrl,
    displayAttribute,
    searchAttribute,
    disabled,
    valueType,
    showLabel,
    showPlaceholder,
    createNew,
    createNewParams,
    disabledItems,
    dropup,
    renderCustomListItem,
    renderCustomOption,
    renderCustomItem,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const searchRef = useRef(null)
    const dropdownRef = useRef(null)
    const fieldRef = useRef()
    const isFirstRender = useRef(true)

    const isDisabled = disabled || isFormDisabled(fieldRef)

    const { data, meta, isLoading, fetchData, loadMore } = useFetchData(
        entityType,
        params,
        condition,
        true,
        false,
        defaultOptions,
        isJSONAPI,
        apiUrl
    )

    useEffect(() => {
        if (!isFirstRender.current && condition) {
            fetchData()
        } else {
            isFirstRender.current = false
        }
    }, [reload])

    useEffect(() => {
        if (outsideClick && setTouched) {
            setTouched(true)
        }
    }, [outsideClick])

    useEffect(() => {
        if (!open && searchRef.current?.value) {
            searchRef.current.value = ''
            fetchData()
        }
        if (!open && dropdownRef.current) dropdownRef.current.scrollTop = 0
    }, [open])

    const getIsSelectedItem = (element, item) => {
        return (
            (element.id || element).toString() === (item.id || item).toString()
        )
    }

    const getIsDisabledItem = (item) =>
        disabledItems.some(
            (el) => (el.id || el).toString() === (item.id || item).toString()
        )

    const handleSelect = (item) => {
        const index = value.findIndex((el) => getIsSelectedItem(el, item))
        if (index === -1) {
            switch (valueType) {
                case SELECT_VALUE_TYPE.STRING:
                    setValue([...value, item.id || item])
                    break
                case SELECT_VALUE_TYPE.OBJECT:
                default:
                    setValue([...value, item])
                    break
            }
        } else {
            const selectedValuesCopy = [...value]
            selectedValuesCopy.splice(index, 1)
            setValue(selectedValuesCopy)
        }
        if (fieldRef.current) {
            fieldRef.current.focus()
        }
    }

    const handleRemove = (event, index) => {
        if (isDisabled) return
        const selectedValuesCopy = [...value]
        selectedValuesCopy.splice(index, 1)
        setValue(selectedValuesCopy)
    }

    const removeValues = () => {
        if (isDisabled) {
            return
        }
        setOpen(false)
        setValue([])
    }

    const handleFocus = () => {
        if (isDisabled) return
        setOpen(true)
        if (searchRef.current) searchRef.current.focus()
    }

    const handleSearchDebounce = useCallback(
        _.debounce((event) => {
            if (isDisabled) return
            const { value } = event.target

            if (dropdownRef.current) dropdownRef.current.scrollTop = 0

            fetchData({ [searchAttribute]: value })
        }, SEARCH_DELAY),
        [isDisabled]
    )

    const handleOpen = () => {
        if (isDisabled) return
        if (!open && searchRef.current) {
            searchRef.current.focus()
        }
        if (open && setTouched) {
            setTouched(true)
        }
        setOpen(!open)
    }

    const handleCreateNew = async () => {
        try {
            if (
                !createNew ||
                !searchRef.current?.value ||
                data.some((item) => item.name === searchRef.current.value)
            ) {
                return
            }
            const newItem = await createEntityService(
                ENTITIES.CREATE_BASIC_ENTITY,
                {
                    name: searchRef.current.value,
                    entity_type: entityType,
                    ...createNewParams,
                },
                false
            )
            await fetchData()
            handleSelect({
                id: newItem.data.id,
                name: newItem.data.attributes.name,
                entityType,
            })
            searchRef.current.value = ''
        } catch (error) {
            setAlert(error.response.data.message, ALERT_TYPES.ERROR)
        }
    }

    const handleLoadMore = () => {
        if (!isLoading && loadMore) {
            fetchData({
                page: meta.currentPage + 1,
                [searchAttribute]: searchRef.current?.value,
            })
        }
    }

    const renderOption = (item) => {
        if (renderCustomOption) {
            return renderCustomOption(item)
        }
        return displayAttribute ? item[displayAttribute] : item
    }

    const renderOptions = () => {
        if (data.length > 0) {
            return (
                !displayAttribute && searchRef.current?.value
                    ? data.filter((item) =>
                          item
                              .toLowerCase()
                              .includes(searchRef.current?.value.toLowerCase())
                      )
                    : data
            ).map((item) => {
                const isDisabled = getIsDisabledItem(item)
                return (
                    <li
                        className={`-result -multiSelect a-mediumText a-lightText
                        ${
                            !isDisabled &&
                            value.some((el) => getIsSelectedItem(el, item))
                                ? '-selected'
                                : ''
                        }${isDisabled ? '-disabled' : ''}`}
                        key={item.id || item}
                        onClick={
                            !isDisabled ? () => handleSelect(item) : undefined
                        }
                    >
                        <div className="-checkbox">
                            <span
                                className={`-insideBox ${
                                    value.some((el) =>
                                        getIsSelectedItem(el, item)
                                    )
                                        ? '-selected'
                                        : null
                                }`}
                            ></span>
                        </div>
                        <div className="aligned-center -gap10">
                            {renderOption(item)}
                        </div>
                    </li>
                )
            })
        }
        if (!renderCustomListItem) {
            return (
                <li className="-result a-lightText a-captionsTextRegular -noResults">
                    {t('general.noItems')}
                </li>
            )
        }
    }

    const getSelectedName = (selectedValue) => {
        if (valueType === SELECT_VALUE_TYPE.OBJECT && displayAttribute) {
            return selectedValue[displayAttribute] || ''
        }
        if (valueType === SELECT_VALUE_TYPE.STRING && displayAttribute) {
            const selectedOption = data.find(
                (item) => (item.id || item).toString() === selectedValue
            )

            return selectedOption ? selectedOption[displayAttribute] : ''
        }
        return selectedValue || ''
    }

    const renderItem = (item) => {
        if (renderCustomItem) {
            return renderCustomItem(item)
        }
        return (
            <span className="label a-mediumText"> {getSelectedName(item)}</span>
        )
    }

    return (
        <div
            scroll-attribute={name}
            className={`m-multiselectGroup ${isDisabled ? '-disabled' : ''}`}
        >
            {showLabel && (
                <FieldLabel
                    label={label || `form.label.${name}`}
                    required={required}
                />
            )}
            <input ref={fieldRef} className="fakeInput" />
            <div className="m-multiselectGroup__container">
                <div
                    className={`m-multiselectGroup__multiselect a-input ${
                        open ? '-open' : ''
                    } ${error ? '-error' : ''} ${
                        value.length > 0 ? '-withClearAllIcon' : ''
                    }`}
                    onClick={handleFocus}
                >
                    {!(searchable && open) && (
                        <span className="m-multiselectGroup__placeholder a-mediumText">
                            {showPlaceholder
                                ? t(placeholder || `form.placeholder.${name}`)
                                : ''}
                        </span>
                    )}
                    {searchable && (
                        <input
                            className="m-selectGroup__searchInput a-input"
                            onClick={handleFocus}
                            onFocus={handleFocus}
                            onChange={handleSearchDebounce}
                            autoComplete="off"
                            ref={searchRef}
                            disabled={isDisabled}
                        />
                    )}
                </div>
                {value.length > 0 && (
                    <div
                        onClick={removeValues}
                        title={t('general.clearAll')}
                        className="-clearAllIcon"
                    >
                        <Icon
                            name={ICONS.CLEAR}
                            size={ICON_SIZE.SIZE20}
                            color={COLORS.DARK_BLUE}
                            title={t('button.clearSelection')}
                        />
                    </div>
                )}
                <span
                    className="m-selectGroup__arrow"
                    onClick={handleOpen}
                ></span>

                <Dropdown
                    open={open}
                    dropdownRef={dropdownRef}
                    handleReachedBottom={handleLoadMore}
                    dropup={dropup}
                >
                    <ul>
                        {renderOptions()}
                        {loadMore && (
                            <li className="m-selectGroup__loader a-captionsTextRegular a-lightText">
                                <span>{t('general.loading')}</span>
                            </li>
                        )}
                        {!loadMore && createNew && searchRef.current?.value && (
                            <li
                                className="-result -create a-mediumTextSemiBold"
                                key="new"
                                onClick={handleCreateNew}
                            >
                                {`${t('general.create')} "${
                                    searchRef.current?.value
                                }"`}
                            </li>
                        )}
                        {!loadMore &&
                            renderCustomListItem &&
                            searchRef.current?.value &&
                            renderCustomListItem(
                                searchRef.current?.value,
                                data,
                                handleSelect,
                                setOpen
                            )}
                    </ul>
                </Dropdown>
            </div>
            {value.length > 0 && (
                <div className="m-multiselectGroup__items">
                    {value.map((item, index) => (
                        <div
                            className={`m-multiselectGroup__item ${
                                renderCustomItem ? '-withCustomItem' : ''
                            } `}
                            key={item.id || item}
                        >
                            {renderItem(item)}
                            <span
                                className="removeItemIcon"
                                onClick={(e) => handleRemove(e, index)}
                            ></span>
                        </div>
                    ))}
                </div>
            )}
            {error && <FieldError error={error} />}
        </div>
    )
}

export const MultiselectMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    entityType: PropTypes.string,
    params: PropTypes.object,
    searchable: PropTypes.bool,
    required: PropTypes.bool,
    reload: PropTypes.any,
    condition: PropTypes.bool,
    defaultOptions: PropTypes.array,
    isJSONAPI: PropTypes.bool,
    apiUrl: PropTypes.string,
    displayAttribute: PropTypes.string,
    searchAttribute: PropTypes.string,
    valuesWithoutAttribute: PropTypes.array,
    disabled: PropTypes.bool,
    valueType: PropTypes.oneOf([
        SELECT_VALUE_TYPE.STRING,
        SELECT_VALUE_TYPE.OBJECT,
    ]),
    showLabel: PropTypes.bool,
    showPlaceholder: PropTypes.bool,
    createNew: PropTypes.bool,
    disabledItems: PropTypes.array,
    createNewParams: PropTypes.object,
}

Multiselect.propTypes = {
    ...MultiselectMainPropTypes,
    value: PropTypes.array.isRequired,
    setValue: PropTypes.func.isRequired,
    setTouched: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    dropup: PropTypes.bool,
    renderCustomListItem: PropTypes.func,
    renderCustomOption: PropTypes.func,
    renderCustomItem: PropTypes.func,
}

Multiselect.defaultProps = {
    value: [],
    displayAttribute: 'name',
    searchAttribute: 'name',
    condition: true,
    disabled: false,
    valueType: SELECT_VALUE_TYPE.OBJECT,
    showLabel: true,
    showPlaceholder: true,
    createNew: false,
    disabledItems: [],
    dropup: false,
}

export default clickOutsideHOC(Multiselect, false, true)
