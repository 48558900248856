const LogoComponent = ({ color1, color2 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="110"
            height="17"
            viewBox="0 0 250 38"
            fill="none"
        >
            <path
                d="M9.2628 29.9648C6.39574 29.9648 4.13518 29.1278 2.48111 27.4537C0.827036 25.7797 0 23.4919 0 20.5903H4.75546C4.75546 22.1527 5.15519 23.3943 5.95466 24.315C6.78169 25.2078 7.91198 25.6542 9.34551 25.6542H13.9356C15.2864 25.6542 16.3753 25.3612 17.2023 24.7753C18.0294 24.1615 18.4429 23.3664 18.4429 22.3899V20.5485C18.4429 19.7952 18.1534 19.1674 17.5745 18.6652C16.9956 18.163 16.1686 17.8142 15.0934 17.6189L7.56738 16.3216C5.22411 15.931 3.41841 15.1498 2.15029 13.978C0.909739 12.8062 0.289463 11.3135 0.289463 9.5V7.19824C0.289463 5.77533 0.661629 4.51982 1.40596 3.43172C2.17786 2.34361 3.25301 1.50661 4.6314 0.920703C6.00979 0.306901 7.60873 0 9.42821 0H14.101C15.8102 0 17.3126 0.362701 18.6083 1.0881C19.9316 1.78561 20.9516 2.79001 21.6683 4.10132C22.3851 5.41263 22.7435 6.91924 22.7435 8.62114H18.0294C18.0294 7.33774 17.6572 6.30543 16.9129 5.52423C16.1961 4.71512 15.2588 4.31057 14.101 4.31057H9.42821C8.13252 4.31057 7.08494 4.60352 6.28547 5.18943C5.51357 5.74743 5.12762 6.51468 5.12762 7.49119V8.9978C5.12762 9.779 5.4033 10.4347 5.95466 10.9648C6.50602 11.467 7.30548 11.8018 8.35306 11.9692L15.8791 13.3084C18.2499 13.7269 20.0694 14.5081 21.3375 15.652C22.6332 16.768 23.2811 18.2048 23.2811 19.9626V22.6828C23.2811 24.1057 22.8951 25.3752 22.1232 26.4912C21.3513 27.5793 20.2624 28.4302 18.8564 29.0441C17.4505 29.6579 15.8377 29.9648 14.0183 29.9648H9.2628Z"
                fill={color1}
            />
            <path
                d="M41.7026 29.63L34.7142 20.7577L32.3158 23.185V29.63H27.5603V0.3348H32.3158V17.7026L41.1237 8.7467H46.7062L37.8156 17.6608L47.1611 29.63H41.7026Z"
                fill={color1}
            />
            <path
                d="M52.9933 6.40308C52.056 6.40308 51.2979 6.12408 50.7189 5.56608C50.1676 4.98018 49.8919 4.24082 49.8919 3.34802C49.8919 2.45521 50.1676 1.72981 50.7189 1.17181C51.2979 0.585902 52.056 0.29295 52.9933 0.29295C53.9306 0.29295 54.6887 0.585902 55.2676 1.17181C55.8466 1.72981 56.136 2.45521 56.136 3.34802C56.136 4.24082 55.8466 4.98018 55.2676 5.56608C54.6887 6.12408 53.9306 6.40308 52.9933 6.40308ZM50.6362 8.7467H55.3917V29.63H50.6362V8.7467Z"
                fill={color1}
            />
            <path
                d="M68.099 29.63C65.7833 29.63 64.0879 29.1417 63.0127 28.1652C61.9651 27.1608 61.4413 25.5844 61.4413 23.4361V0.3348H66.1968V23.2269C66.1968 24.036 66.3622 24.6219 66.693 24.9846C67.0238 25.3194 67.5752 25.4868 68.3471 25.4868H70.332V29.63H68.099Z"
                fill={color1}
            />
            <path
                d="M79.7292 29.63C77.4135 29.63 75.718 29.1417 74.6429 28.1652C73.5953 27.1608 73.0715 25.5844 73.0715 23.4361V0.3348H77.827V23.2269C77.827 24.036 77.9924 24.6219 78.3232 24.9846C78.654 25.3194 79.2054 25.4868 79.9773 25.4868H81.9622V29.63H79.7292Z"
                fill={color1}
            />
            <path
                d="M96.6588 0.3348H101.001L115.639 21.7621V0.3348H120.353V29.63H115.97L101.373 8.28634V29.63H96.6588V0.3348Z"
                fill={color2}
            />
            <path
                d="M141.082 29.63C140.007 29.63 139.139 29.3091 138.477 28.6674C137.843 28.0257 137.526 27.1747 137.526 26.1145V0.3348H150.717C152.868 0.3348 154.756 0.753303 156.382 1.59031C158.037 2.39941 159.305 3.55727 160.187 5.06388C161.097 6.57048 161.551 8.31424 161.551 10.2952V18.8326C161.551 22.3759 160.642 25.0683 158.822 26.9097C157.03 28.7232 154.384 29.63 150.883 29.63H141.082ZM150.759 25.3194C152.716 25.3194 154.191 24.7614 155.183 23.6454C156.203 22.5015 156.713 20.8414 156.713 18.6652V10.4626C156.713 8.73274 156.148 7.33774 155.018 6.27753C153.888 5.18943 152.413 4.64537 150.593 4.64537H142.364V25.3194H150.759Z"
                fill={color1}
            />
            <path
                d="M173.682 29.9648C172.001 29.9648 170.512 29.6439 169.216 29.0022C167.948 28.3605 166.956 27.4537 166.239 26.2819C165.55 25.1101 165.205 23.757 165.205 22.2225V16.6145C165.205 14.9963 165.55 13.5734 166.239 12.3458C166.956 11.0903 167.948 10.1278 169.216 9.45815C170.512 8.76065 172.001 8.41189 173.682 8.41189H176.991C179.499 8.41189 181.512 9.15125 183.028 10.63C184.544 12.1087 185.302 14.0756 185.302 16.5308V20.7159H169.961V22.2225C169.961 23.3385 170.292 24.2313 170.953 24.9009C171.642 25.5705 172.552 25.9053 173.682 25.9053H176.866C177.969 25.9053 178.851 25.6402 179.513 25.1101C180.202 24.5521 180.547 23.8267 180.547 22.9339H185.22C185.22 25.0543 184.448 26.7562 182.904 28.0396C181.36 29.3231 179.334 29.9648 176.825 29.9648H173.682ZM180.629 17.1167V16.5308C180.629 15.3032 180.299 14.3128 179.637 13.5595C178.975 12.8062 178.107 12.4295 177.032 12.4295H173.682C172.552 12.4295 171.629 12.8341 170.912 13.6432C170.223 14.4244 169.878 15.4567 169.878 16.7401V17.1167H180.629Z"
                fill={color1}
            />
            <path
                d="M189.575 8.7467H194V11.2159C194.579 10.3789 195.323 9.70925 196.233 9.20705C197.17 8.67694 198.08 8.41189 198.962 8.41189H201.691C204.09 8.41189 206.02 9.15125 207.481 10.63C208.969 12.0808 209.714 14.0059 209.714 16.4053V21.2599C209.714 23.8825 208.983 25.989 207.522 27.5793C206.061 29.1696 204.117 29.9648 201.691 29.9648H198.921C198.121 29.9648 197.294 29.7695 196.44 29.3789C195.585 28.9604 194.882 28.4163 194.331 27.7467V38H189.575V8.7467ZM201.195 25.8216C202.325 25.8216 203.235 25.3752 203.924 24.4824C204.641 23.5896 205 22.4178 205 20.967V16.6982C205 15.4706 204.655 14.4802 203.966 13.7269C203.277 12.9457 202.367 12.5551 201.237 12.5551H198.59C197.653 12.5551 196.784 12.9317 195.985 13.685C195.213 14.4104 194.662 15.359 194.331 16.5308V21.3855C194.469 22.6968 194.924 23.7709 195.695 24.6079C196.495 25.417 197.446 25.8216 198.549 25.8216H201.195Z"
                fill={color1}
            />
            <path
                d="M222.651 29.63C220.308 29.63 218.599 29.0999 217.524 28.0396C216.449 26.9794 215.911 25.3054 215.911 23.0176V12.8899H212.272V8.7467H216.035V2.42731H220.666V8.7467H226.042V12.8899H220.666V22.8502C220.666 23.7709 220.846 24.4405 221.204 24.859C221.562 25.2775 222.128 25.4868 222.899 25.4868H226.042V29.63H222.651Z"
                fill={color1}
            />
            <path
                d="M230.523 0.3348H235.279V11.0903C235.913 10.2812 236.685 9.6395 237.594 9.1652C238.532 8.66299 239.442 8.41189 240.324 8.41189H242.97C245.093 8.41189 246.788 9.09545 248.056 10.4626C249.352 11.8297 250 13.6292 250 15.8612V29.63H245.245V15.9868C245.245 14.9824 244.983 14.1733 244.459 13.5595C243.963 12.9178 243.301 12.5969 242.474 12.5969H239.827C238.752 12.5969 237.787 13.0015 236.933 13.8106C236.078 14.6197 235.527 15.6799 235.279 16.9912V29.63H230.523V0.3348Z"
                fill={color1}
            />
        </svg>
    )
}
export default LogoComponent
