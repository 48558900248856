import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { POSITIONS } from 'constants/enums'

import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'

const DiveSummary = ({
    data,
    diveRecordId,
    verification,
    diveRecordType,
    fetchEntity,
}) => {
    const t = useTranslate()

    if (!data) return null

    const mentalWellnessElements = data.mentalWellnessElements?.map(
        (item) => item.name
    )
    const pressureInjuries = data.pressureInjuries?.map((item) => item.name)
    const equipmentFailures = data.equipmentFailures?.map((item) => item.name)

    const dietFragment = () => {
        return !!(data.numberOfMeals || data.typeOfFood || data.calorieIntake)
    }

    const hydratationFragment = () => {
        return !!(data.litersOfWater || data.litersOfOtherLiquids)
    }

    const medicationFragment = () => {
        return !!(
            data.prescribedMedication ||
            data.nonPrescribedMedication ||
            data.supplementsConsumed
        )
    }

    const physicalActivityFragment = () => {
        return !!(
            data.physicalActivityAnomalies ||
            data.lightCardio ||
            data.moderateCardio ||
            data.strenuousCardio
        )
    }

    const hygieneFragment = () => {
        return !!(data.personalHygiene || data.chamberHygiene)
    }

    const notesAndAttachmentsFragment = () => {
        return !!(data.notes || data?.files?.length > 0)
    }

    return (
        <CardContainer noBorderCard={!verification}>
            {(Number(diveRecordType) === POSITIONS.DIVER.id ||
                Number(diveRecordType) === POSITIONS.DIVING_MEDIC.id) && (
                <Fragment>
                    {data.rest && (
                        <div className="-infoGroup column">
                            <span className="-mb20 a-bodyTextRegular">
                                {t('general.rest')}
                            </span>

                            <Info
                                label="form.label.rest"
                                value={data.rest}
                                withSpaceBetween
                            />
                        </div>
                    )}
                    {dietFragment() && (
                        <div className="-infoGroup column">
                            <span className="-mb20 a-bodyTextRegular">
                                {t('general.diet')}
                            </span>
                            {data.numberOfMeals && (
                                <Info
                                    label="form.label.numberOfMeals"
                                    value={data.numberOfMeals}
                                    withSpaceBetween
                                />
                            )}
                            {data.typeOfFood && (
                                <Info
                                    label="form.label.typeOfFood"
                                    value={data.typeOfFood}
                                    withSpaceBetween
                                />
                            )}
                            {data.calorieIntake && (
                                <Info
                                    label="form.label.calorieIntake"
                                    value={data.calorieIntake}
                                    withSpaceBetween
                                />
                            )}
                        </div>
                    )}
                    {hydratationFragment() && (
                        <div className="-infoGroup column">
                            <span className="-mb20 a-bodyTextRegular">
                                {t('general.hydratation')}
                            </span>
                            {data.litersOfWater && (
                                <Info
                                    label="form.label.litersOfWater"
                                    value={data.litersOfWater}
                                    withSpaceBetween
                                />
                            )}
                            {data.litersOfOtherLiquids && (
                                <Info
                                    label="form.label.litersOfOtherLiquids"
                                    value={data.litersOfOtherLiquids}
                                    withSpaceBetween
                                />
                            )}
                        </div>
                    )}
                    {medicationFragment() && (
                        <div className="-infoGroup column">
                            <span className="-mb20 a-bodyTextRegular">
                                {t('general.medicationSupplements')}
                            </span>
                            {data.prescribedMedication && (
                                <Info
                                    label="form.label.prescribedMedication"
                                    value={data.prescribedMedication}
                                    withSpaceBetween
                                />
                            )}
                            {data.nonPrescribedMedication && (
                                <Info
                                    label="form.label.nonPrescribedMedication"
                                    value={data.nonPrescribedMedication}
                                    withSpaceBetween
                                />
                            )}
                            {data.supplementsConsumed && (
                                <Info
                                    label="form.label.supplementsConsumed"
                                    value={data.supplementsConsumed}
                                    withSpaceBetween
                                />
                            )}
                        </div>
                    )}
                    {physicalActivityFragment() && (
                        <div className="-infoGroup column">
                            <span className="-mb20 a-bodyTextRegular">
                                {t('general.physicalActivity')}
                            </span>
                            {data.physicalActivityAnomalies && (
                                <Info
                                    label="form.label.physicalActivityAnomalies"
                                    value={data.physicalActivityAnomalies}
                                    withSpaceBetween
                                />
                            )}
                            {data.lightCardio && (
                                <Info
                                    label="form.label.lightCardio"
                                    value={data.lightCardio}
                                    withSpaceBetween
                                />
                            )}
                            {data.moderateCardio && (
                                <Info
                                    label="form.label.moderateCardio"
                                    value={data.moderateCardio}
                                    withSpaceBetween
                                />
                            )}
                            {data.strenuousCardio && (
                                <Info
                                    label="form.label.strenuousCardio"
                                    value={data.strenuousCardio}
                                    withSpaceBetween
                                />
                            )}
                        </div>
                    )}
                    {data.mentalWellnessInfluenced && (
                        <div className="-infoGroup column">
                            <span className="-mb20 a-bodyTextRegular">
                                {t('general.mentalWellness')}
                            </span>
                            <Info
                                label="form.label.mentalWellnessInfluenced"
                                value={
                                    data.mentalWellnessInfluenced ? 'Yes' : 'No'
                                }
                                withSpaceBetween
                            />
                            {data.mentalWellnessDescription && (
                                <Info
                                    label="form.label.mentalWellnessDescription"
                                    value={data.mentalWellnessDescription}
                                    withSpaceBetween
                                />
                            )}
                            {mentalWellnessElements?.length > 0 && (
                                <Info
                                    label="form.label.mentalWellnessElements"
                                    value={mentalWellnessElements}
                                    color="gray"
                                    withSpaceBetween
                                />
                            )}
                        </div>
                    )}
                    {hygieneFragment() && (
                        <div className="-infoGroup column">
                            <span className="-mb20 a-bodyTextRegular">
                                {t('general.hygiene')}
                            </span>
                            {data.personalHygiene && (
                                <Info
                                    label="form.label.personalHygiene"
                                    value={data.personalHygiene}
                                    withSpaceBetween
                                />
                            )}
                            {data.chamberHygiene && (
                                <Info
                                    label="form.label.chamberHygiene"
                                    value={data.chamberHygiene}
                                    withSpaceBetween
                                />
                            )}
                        </div>
                    )}
                </Fragment>
            )}
            <div className="-infoGroup ">
                {data.anyIncidentsOnDive ? (
                    <Fragment>
                        <span className="a-bodyTextRegular">
                            {t('general.incidentsOnDive')}
                        </span>
                    </Fragment>
                ) : (
                    <span className="a-bodyTextRegular -mt20">
                        {t('general.thereWereNoIncidents')}
                    </span>
                )}
                {data.abortedDive && (
                    <div className="-mt30">
                        <span className="a-bodyTextRegular a-lightText">
                            {t('general.abortedDive')}
                        </span>
                        <Info
                            label="form.label.abortedDescription"
                            value={data.abortedDescription}
                            withSpaceBetween
                        />
                    </div>
                )}
                {data.traumaInjuryLevel && (
                    <div className="-mt30">
                        <Info
                            label="form.label.traumaInjuryLevel"
                            value={data.traumaInjuryLevel.name}
                            withSpaceBetween
                        />
                        {data.traumaInjuryDescription && (
                            <Info
                                label="form.label.traumaInjuryDescription"
                                value={data.traumaInjuryDescription}
                                withSpaceBetween
                            />
                        )}
                    </div>
                )}
                {data.decompressionIllness && (
                    <div className="-mt30">
                        <span className="a-bodyTextRegular a-lightText">
                            {t('form.label.decompressionIllness')}
                        </span>
                        <Info
                            label="form.label.decompressionIllness"
                            value={data.decompressionIllness.name}
                            withSpaceBetween
                        />
                        {data.decompressionIllnessDescription && (
                            <Info
                                label="form.label.decompressionIllnessDescription"
                                value={data.decompressionIllnessDescription}
                                withSpaceBetween
                            />
                        )}
                    </div>
                )}
                {data.pressureInjuries && (
                    <div className="-mt30">
                        <span className="a-bodyTextRegular a-lightText">
                            {t('form.label.pressureInjuries')}
                        </span>
                        <Info
                            label="form.label.pressureInjuries"
                            value={pressureInjuries}
                            color="gray"
                            withSpaceBetween
                        />
                        {data.pressureInjuryDescription && (
                            <Info
                                label="form.label.pressureInjuryDescription"
                                value={data.pressureInjuryDescription}
                                withSpaceBetween
                            />
                        )}
                    </div>
                )}
                {data.equipmentFailures && (
                    <div className="-mt30">
                        <Info
                            label="form.label.equipmentFailures"
                            value={equipmentFailures}
                            color="gray"
                            withSpaceBetween
                        />
                        {data.equipmentFailureDescription && (
                            <Info
                                label="form.label.equipmentFailureDescription"
                                value={data.equipmentFailureDescription}
                                withSpaceBetween
                            />
                        )}
                    </div>
                )}
                {data.closeCall && (
                    <div className="-mt30">
                        <span className="a-bodyTextRegular a-lightText">
                            {t('form.label.closeCall')}
                        </span>
                        <Info
                            label="form.label.closeCall"
                            value={data.closeCall.name}
                            withSpaceBetween
                        />
                        {data.closeCallPresentDescription && (
                            <Info
                                label="form.label.closeCallPresentDescription"
                                value={data.closeCallPresentDescription}
                                withSpaceBetween
                            />
                        )}
                    </div>
                )}
            </div>
            {notesAndAttachmentsFragment() && (
                <div className="-infoGroup column">
                    <span className="-mb10 a-bodyTextRegular">
                        {t('general.notesAndAttachments')}
                    </span>
                    {data.notes && (
                        <Info
                            label="form.label.notes"
                            value={data.notes}
                            column
                        />
                    )}
                    {data?.files?.length !== 0 && (
                        <InfoFiles
                            label="general.attachments"
                            files={data.files}
                            fetchEntity={fetchEntity}
                        />
                    )}
                </div>
            )}
        </CardContainer>
    )
}

DiveSummary.propTypes = {
    data: PropTypes.object,
    diveRecordId: PropTypes.number,
    verification: PropTypes.bool,
}

export default DiveSummary
