import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { isFormDisabled } from 'utils/form'

import { INPUT_FILED_TYPE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import FieldError from 'components/FieldError'
import FieldLabel from 'components/FieldLabel'
import Icon from 'components/Icon'
import IconButton from './IconButton'

const Input = ({
    label,
    placeholder,
    type,
    required,
    icon,
    size,
    iconColor,
    onIconClick,
    error,
    disabled,
    inputRef,
    units,
    showLabel,
    showPlaceholder,
    onKeyDown,
    mainSearch,
    autoCompleteOff,
    ...props
}) => {
    const t = useTranslate()
    const [showText, setShowText] = useState(false)

    const fieldRef = useRef()

    const isDisabled = disabled || isFormDisabled(inputRef || fieldRef)

    const isPassword = type === INPUT_FILED_TYPE.PASSWORD

    const handleIconClick = () => {
        if (isPassword) {
            setShowText(!showText)
        } else if (onIconClick) {
            onIconClick()
        }
    }

    const handleKeyDown = (event) => {
        if (
            type === INPUT_FILED_TYPE.NUMBER &&
            ['e', 'E', '-', '+'].includes(event.key)
        ) {
            event.preventDefault()
        }

        if (onKeyDown) {
            onKeyDown(event)
        }
    }

    return (
        <div
            scroll-attribute={props.name}
            className={`m-inputGroup ${icon || isPassword ? '-icon' : ''} 
            ${showLabel ? '-label' : ''}
            ${error ? '-error' : ''} ${isDisabled ? '-disabled' : ''}
            ${units ? '-units' : ''}
            ${isPassword ? '-password' : ''}`}
        >
            {showLabel && (
                <FieldLabel
                    htmlFor={props.name}
                    label={label || `form.label.${props.name}`}
                    required={required}
                />
            )}
            <input
                {...props}
                ref={inputRef || fieldRef}
                className={`a-input ${mainSearch ? '-mainSearch' : ''}`}
                placeholder={
                    showPlaceholder
                        ? t(placeholder || `form.placeholder.${props.name}`)
                        : ''
                }
                type={isPassword && showText ? INPUT_FILED_TYPE.TEXT : type}
                onKeyDown={handleKeyDown}
                disabled={isDisabled}
                autoComplete={autoCompleteOff ? 'new-password' : ''}
            />
            {icon && !units && (
                <Icon
                    name={icon}
                    size={size}
                    color={iconColor}
                    onClick={handleIconClick}
                />
            )}
            {isPassword && (
                <IconButton
                    icon={showText ? ICONS.SHOW_PASSWORD : ICONS.HIDE_PASSWORD}
                    iconColor={COLORS.DARK_BLUE_60}
                    onClick={handleIconClick}
                />
            )}
            {!icon && units && (
                <span className="m-inputGroup__units a-mediumText a-lightText -opacity-60">
                    {t(units)}
                </span>
            )}
            {error && <FieldError error={error} />}
        </div>
    )
}

export const InputMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([
        INPUT_FILED_TYPE.TEXT,
        INPUT_FILED_TYPE.PASSWORD,
        INPUT_FILED_TYPE.EMAIL,
        INPUT_FILED_TYPE.FILE,
        INPUT_FILED_TYPE.CHECKBOX,
        INPUT_FILED_TYPE.NUMBER,
    ]),
    required: PropTypes.bool,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    onIconClick: PropTypes.func,
    disabled: PropTypes.bool,
    inputRef: PropTypes.object,
    units: PropTypes.string,
    showLabel: PropTypes.bool,
    showPlaceholder: PropTypes.bool,
    onKeyDown: PropTypes.func,
    mainSearch: PropTypes.bool,
    autoCompleteOff: PropTypes.bool,
}

Input.propTypes = {
    ...InputMainPropTypes,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
}

Input.defaultProps = {
    type: INPUT_FILED_TYPE.TEXT,
    required: false,
    disabled: false,
    showLabel: true,
    showPlaceholder: true,
    mainSearch: false,
    autoCompleteOff: false,
}

export default Input
