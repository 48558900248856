import { useTranslate } from 'react-polyglot'
import { useContext } from 'react'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { editEntityService } from 'services/entity.service'

import handleLogout from 'utils/logoutHelper'

import Modal from 'components/Modal'

import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'

const DeactivateAccount = ({ deactivateAccount, setDeactivateAccount }) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const { setCurrentUser, setCurrentCompany, userId } = useContext(
        CurrentUserContext
    )

    const handleConfirm = () => {
        closeConfirmationModal()
        handleLogout(setCurrentUser, setCurrentCompany)
    }

    const handleDeactivateAccount = async () => {
        try {
            await editEntityService(ENTITIES.USER, String(userId), {
                isDeactivated: true,
            })
            showConfirmationModal({
                title: 'general.success',
                message: 'message.deactivatedText',
                icon: ICONS.CHECKMARK_CIRCLE,
                iconColor: COLORS.GREEN_60,
                iconSize: ICON_SIZE.SIZE80,
                confirmLabel: 'button.okClose',
                handleConfirm: () => handleConfirm(),
                hideCancel: true,
            })
            setDeactivateAccount(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <Modal
            open={deactivateAccount}
            setOpen={setDeactivateAccount}
            type="-smallModal -confirmModal"
            title="general.deactivateAccount"
            buttons={{
                nextBtn: {
                    label: 'button.deactivate',
                    handleClick: handleDeactivateAccount,
                },
            }}
        >
            <div className="_wr">
                <div className="_w">
                    <p className="a-bodyTextRegular text-align-center">
                        {t('general.deactivateAccountText')}
                    </p>
                </div>
            </div>
        </Modal>
    )
}

export default DeactivateAccount
