const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    REGISTER_PASSWORD: '/register-password',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    CREATE: '/create',
    EDIT: '/edit',
    GALLERY: '/gallery',
    CHAT: '/chat',
    FAQ: '/faq',
    CALENDAR: '#',
    NOTIFICATION: '/notification',
    LEGAL: '/legal',
    SKILLS_MANAGEMENT: '/skills-management',
    NOT_FOUND_PAGE: '/page-not-found',
    DIVER_ONBOARDING: '/diver-onboarding',
    PROJECT: '/project',
    TERMS: '/terms-and-conditions',
    PRIVACY: '/privacy-policy',
    RECORD: '/record',
    DIVING_CONTRACTOR_PROFILE: '/company',
    DIVER_PROFILE: '/user',
    DIVE_TEAM: '/dive-team',
    MY_DIVE_PROJECTS: '/my-dive-projects',
    DIVE_RECORD: 'dive-record',
    DIVE_RECORDS: 'dive-records',
    GENERAL_INFORMATION: 'general-information',
    EQUIPMENT: 'equipment',
    DIVE_PROFILE: 'dive-profile',
    WORK_PERFORMED: 'work-performed',
    DIVE_SUMMARY: 'dive-summary',
    VERIFICATION: 'verification',
    SETTINGS: '/settings',
    DIVE_RECORD_SUMMARY: 'dive-record-summary',
    UNITS_OF_MEASUREMENT: 'units-of-measurement',
    DATA_ACCESS_CONTROL: 'data-access-control',
    MEDICAL_INFO: 'medical-info',
    TRAVEL_DOCUMENTATION: 'travel-documentation',
    TRAINING_RECORDS: 'training-records',
    PERSONAL_EQUIPMENT: 'personal-equipment',
    PAST_EMPLOYMENT: 'past-employment',
    MY_PROJECT_RECORDS: 'my-project-records',
    HELP_AND_INFO: 'help-and-info',
    EXPLORE_SKILL_AND_DEPTH: 'explore-skill-and-depth',
    VERIFICATION_LEVELS: 'verification-levels',
    CREATE_A_PROJECT: 'create-a-project',
    CREATE_HISTORICAL_DIVE_RECORD: 'create-historical-dive-record',
    CREATE_PERSONAL_ACCOUNT: 'create-personal-account',
    SEND_DIVE_RECORD_FOR_VERIFICATION_LEVEL_ONE:
        'send-dive-record-for-verification-level-one',
    SIGN_UP_AS_ORGANIZATION: 'sing-up-as-organization',
    UPDATE_ORGANIZATION_PROFILE: 'update-organization-profile',
    UPDATE_PERSONAL_INFORMATION: 'update-personal-information',
    VERIFY_DIVE_RECORD_AS_A_SUPERVISOR_LEVEL_ONE:
        'verify-dive-record-as-a-supervisor-level-one',
    VERIFY_IDENTITY_USING_PERSONA: 'verify-identity-using-persona',
    RELEASE_NOTES: 'release-notes',
    RELEASE_0_9_0: 'release-0-9-0',
    RELEASE_0_9_1: 'release-0-9-1',
    RELEASE_1_0_0: 'release-1-0-0',
    RELEASE_1_1_0: 'release-1-1-0',
    EXPORT_CV: 'export-cv',
    ACCOUNT_MANAGEMENT: 'account-management',
    BADGES: 'badges',
    GENERAL_PREFERENCES: 'general-preferences',
    SIGN_IN_AND_SECURITY: 'sign-in-and-security',
    NOTIFICATIONS: 'notifications',
    DECLINE_EMAIL_CHANGE: '/decline_email_change',
    HOW_TO: 'how-to',
}

export default ROUTES
