import React from 'react'
import ROUTES from 'constants/routes'

import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import { getPositionImage } from 'utils/positionImage'
import { formatByNumberOfPills } from 'utils/cardHelper'
import ICONS from 'constants/icons'
import Button from '../Button'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
} from '../../constants/enums'
import COLORS from '../../constants/colors'
import { useNavigate } from 'react-router-dom'
import CardHeader from 'components/card/CardHeader'
import Icon from 'components/Icon'
import CardFooter from 'components/card/CardFooter'

const JobCard = ({ item }) => {
    const navigate = useNavigate()

    const renderImage = (position) => (
        <div className="a-jobCard__image">
            <Icon name={getPositionImage(position)} />
        </div>
    )

    const handleJobClick = (item) => {
        navigate(`${ROUTES.PROJECT}/${item.projectId}`)
    }

    return (
        <CardContainer
            cardImage={renderImage(item.role)}
            link={`${ROUTES.PROJECT}/${item.projectId}`}
        >
            <CardHeader title={item.role} />
            <Info
                value={item.projectName}
                iconName={ICONS.DIVE_PROJECTS_OUTLINE}
            />
            <InfoRow withColumns>
                <Info value={item.companyName} iconName={ICONS.BUILDING} />

                {item.skillPack.length !== 0 && (
                    <Info
                        value={item.countryOfWork}
                        iconName={ICONS.LOCATION}
                    />
                )}

                {(item.fromPayRate || item.toPayRate) && (
                    <Info
                        value={
                            (item.fromPayRate && !item.toPayRate
                                ? 'from '
                                : '') +
                            (item.fromPayRate || 0) +
                            (item.toPayRate ? '-' : '') +
                            (item.toPayRate || '') +
                            '$'
                        }
                        iconName={ICONS.WALLET}
                    />
                )}
            </InfoRow>

            {item.description && (
                <Info value={item.description} descriptionText />
            )}

            <InfoRow withButtons>
                {item.skillPack.length !== 0 && (
                    <Info
                        value={formatByNumberOfPills(item.skillPack, 4)}
                        color="blue"
                    />
                )}

                {item.skillPack.length === 0 && (
                    <Info
                        value={item.countryOfWork}
                        iconName={ICONS.LOCATION}
                    />
                )}
            </InfoRow>

            <CardFooter withTopMargin>
                <Button
                    label="button.viewMore"
                    buttonSize={BUTTON_SIZE.XSMALL}
                    onClick={() => handleJobClick(item)}
                    btnClass={BUTTON_STATUS.TERTIARY}
                    icon={ICONS.ARROW_RIGHT}
                    iconColor={COLORS.LIGHT_BLUE}
                    iconPosition={ICON_POSITION.RIGHT}
                />
            </CardFooter>
        </CardContainer>
    )
}

export default JobCard
