import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'

import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import Loader from 'components/Loader'
import InputField from 'components/formFields/InputField'
import SelectField from 'components/formFields/SelectField'
import SelectMultiselectGroupField from 'components/formFields/SelectMultiselectGroupField'
import FocusError from 'components/FocusError'

const AvailablePositionsForm = ({
    initialData,
    availablePositions,
    setAvailablePositions,
    positionsError,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [resetSkills, setResetSkills] = useState(false)

    const requiredMessage = t('form.error.required')

    const availablePositionsInitialValues = {
        diverPosition: initialData?.diverPosition ?? null,
        numOfWorkers: initialData?.numOfWorkers ?? '',
        fromPayRate: initialData?.fromPayRate ?? '',
        toPayRate: initialData?.toPayRate ?? '',
        projectPositionSkill: initialData?.projectPositionSkill ?? [],
        description: initialData?.description ?? '',
    }

    const availablePositionsValidation = Yup.object().shape({
        diverPosition: Yup.object().required(requiredMessage),
        numOfWorkers: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(1, t('form.error.invalidNumber'))
            .required(requiredMessage),
        fromPayRate: Yup.number()
            .integer(t('form.error.onlyIntegerNumber'))
            .min(0, t('form.error.invalidNumber')),
        toPayRate: Yup.number()
            .integer(t('form.error.onlyIntegerNumber'))
            .min(0, t('form.error.invalidNumber'))
            .test('toPayRate', (value, { parent, createError }) => {
                if (Number(value) < Number(parent.fromPayRate)) {
                    return createError({
                        message: t('form.error.mustBeMoreThanFrom'),
                        path: 'toPayRate',
                    })
                }
                return true
            }),
        description: Yup.string().max(100, t('form.error.valueTooLong')),
    })

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        try {
            setSubmitting(true)
            setAvailablePositions([...availablePositions, values])
            resetForm({})
            setResetSkills(!resetSkills)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={availablePositionsInitialValues}
            onSubmit={handleSubmit}
            validationSchema={availablePositionsValidation}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <div className="_wr">
                        <div className="_w">
                            <span className="_12 a-bodyTextMedium">
                                {t('general.availablePositions')}{' '}
                                <span className="redStar">*</span>
                            </span>
                            {positionsError &&
                                availablePositions.length === 0 && (
                                    <span className="_12 errorMessage -active">
                                        {positionsError}
                                    </span>
                                )}
                            <div className="_12 _l4 -mt20">
                                <SelectField
                                    name="diverPosition"
                                    label="form.label.positionType"
                                    placeholder="form.placeholder.positionType"
                                    entityType={ENTITIES.DIVER_POSITION}
                                    searchable
                                    required
                                />
                            </div>
                            <div className="_12 _l4 -mt20">
                                <InputField
                                    name="numOfWorkers"
                                    type={INPUT_FILED_TYPE.NUMBER}
                                    label="form.label.numberOfWorkersNeeded"
                                    placeholder="form.placeholder.numberOfWorkersNeeded"
                                    required
                                />
                            </div>
                        </div>
                        <div className="_w">
                            <div className="_6 _l2 first-field">
                                <InputField
                                    name="fromPayRate"
                                    label="form.label.payRate"
                                    type={INPUT_FILED_TYPE.NUMBER}
                                />
                            </div>
                            <div className="_6 _l2 fakeLabel second-field">
                                <InputField
                                    name="toPayRate"
                                    label="general.fakeLabel"
                                    type={INPUT_FILED_TYPE.NUMBER}
                                />
                            </div>
                            <div className="_12 _l4">
                                <InputField
                                    name="description"
                                    placeholder="form.placeholder.description"
                                />
                            </div>
                        </div>
                        <div className="_w">
                            <div className="_12 _l8 oneLineSelectGroup">
                                <SelectMultiselectGroupField
                                    name="projectPositionSkill"
                                    description="general.requiredSkillsPerPosition"
                                    fieldsName={['skillCategory', 'skill']}
                                    fieldsShowLabel={[false, false]}
                                    fieldsPlaceholder={[
                                        'form.placeholder.category',
                                        'form.placeholder.skill',
                                    ]}
                                    fieldsEntityType={[
                                        ENTITIES.SKILL_CATEGORY,
                                        ENTITIES.SKILL,
                                    ]}
                                    fieldsRequired={[false, false]}
                                    disableAddOnlyForBoth={true}
                                    buttonLabel="button.addSkill"
                                    secondFieldRelation="skillCategories.id"
                                    fieldsSearchable={[true, true]}
                                    reset={resetSkills}
                                />
                            </div>
                        </div>
                        <div className="ofs_2 _8 ofs_l5 _l3 -mt20 justify-center">
                            <Button
                                label="button.addPosition"
                                btnClass={BUTTON_STATUS.SECONDARY}
                                buttonSize={BUTTON_SIZE.MEDIUM}
                                type={BUTTON_TYPE.SUBMIT}
                                icon={ICONS.PLUS}
                                iconColor={COLORS.LIGHT_BLUE}
                                disabled={isSubmitting}
                            />
                        </div>

                        {isSubmitting && <Loader />}
                    </div>
                </Form>
            )}
        </Formik>
    )
}

AvailablePositionsForm.propTypes = {
    initialData: PropTypes.object,
    availablePositions: PropTypes.array,
    setAvailablePositions: PropTypes.func,
    positionsError: PropTypes.string,
}

AvailablePositionsForm.defaultProps = {
    availablePositions: [],
    positionsError: '',
}

export default AvailablePositionsForm
