import React from 'react'
import PropTypes from 'prop-types'

const InfoRow = ({ children, withButtons, withColumns, gap }) => {
    return (
        <div
            className={`-infoRow ${withButtons ? '-withButtonsWrapper' : ''} ${
                withColumns ? '-withColumns' : ''
            } -gap${gap}`}
        >
            {children}
        </div>
    )
}

InfoRow.propTypes = {
    withButtons: PropTypes.bool,
    withColumns: PropTypes.bool,
    gap: PropTypes.number,
}

InfoRow.defaultProps = {
    withButtons: false,
    withColumns: false,
    gap: 15,
}

export default InfoRow
