import React, { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { createEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES, BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ROUTES from 'constants/routes'

import { DIVE_RECORD_STEPS } from 'screens/diver/diveRecord/constants/diveRecordSteps'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Input from 'components/Input'

const VerificationLinkModal = ({ setOpen, diveRecord, diveRecordName }) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [verificationToken, setVerificationToken] = useState()
    const [linkCopied, setLinkCopied] = useState(false)

    useEffect(() => {
        const getVerificationToken = async () => {
            try {
                const { data } = await createEntityService(
                    `${ENTITIES.DIVE_RECORD_VERIFICATION_TOKEN}/${diveRecord.id}`
                )
                setVerificationToken(data.firstLevelVerificationToken)
            } catch (error) {
                setAlert(error, ALERT_TYPES.ERROR)
                setOpen(false)
            }
        }
        getVerificationToken()
    }, [])

    const verificationLink = `${window.location.origin}/${ROUTES.DIVE_RECORD_SUMMARY}/${verificationToken}/${DIVE_RECORD_STEPS[0].route}`

    const handleCopyLink = () => {
        navigator.clipboard.writeText(verificationLink)
        setLinkCopied(true)
    }

    if (!verificationToken) return null

    return (
        <Modal
            closeOnClickOutside={false}
            type="-confirmModal -smallModal -diveRecordModal -verificationModal"
            setOpen={setOpen}
            title={`${t(
                'general.shareHistoricalDiveRecord'
            )} "${diveRecordName}"`}
            buttons={{
                nextBtn: {
                    label: 'button.done',
                    handleClick: () => setOpen(false),
                },
            }}
        >
            <p className="a-bodyTextRegular _12 a-centeredText">
                {t('general.shareVerificationLinkDescription')}
            </p>
            <div className="_12 verificationLink aligned-center -mt20">
                <Input
                    name="verificationLink"
                    defaultValue={verificationLink}
                    showLabel={false}
                    showPlaceholder={false}
                    readOnly
                />
                <Button
                    label={linkCopied ? 'button.linkCopied' : 'button.copyLink'}
                    btnClass={BUTTON_STATUS.TERTIARY}
                    buttonSize={BUTTON_SIZE.LARGE}
                    icon={linkCopied ? null : ICONS.LINK}
                    iconColor={COLORS.LIGHT_BLUE}
                    onClick={handleCopyLink}
                />
            </div>
        </Modal>
    )
}

export default VerificationLinkModal
