import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { BASE_URL } from 'utils/axiosClient'
import { renderRoleNames } from 'utils/roleNamesHelper'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const CustomUserOption = ({ item }) => {
    return (
        <Fragment>
            <div className="a_customOptionItem__imageWrapper aligned-center justify-center -larger">
                <div className="-image aligned-center justify-center">
                    {item.avatarPath ? (
                        <img src={`${BASE_URL}${item.avatarPath}`} alt="user" />
                    ) : (
                        <Icon
                            name={ICONS.USER}
                            size={ICON_SIZE.SIZE12}
                            color={COLORS.DARK_BLUE_60}
                        />
                    )}
                </div>
            </div>

            <div className="column">
                <span className="a-captionsTextRegular a-darkBlueText">
                    {item.fullName}
                </span>
                <span className="a-captionsTextRegular a-lightText -opacity-60">
                    {renderRoleNames(item.roleId)}
                </span>
            </div>
        </Fragment>
    )
}

CustomUserOption.propTypes = {
    item: PropTypes.PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default CustomUserOption
