import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { getEntityService } from 'services/entity.service'
import { getUserToken, setUserToken } from 'services/localStorage.service'

import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
    ICON_SIZE,
} from 'constants/enums'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Icon from 'components/Icon'
import Button from 'components/Button'
import Loader from 'components/Loader'

const DeclineEmailChange = () => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { hash } = useParams()

    const { setAlert } = useContext(AlertContext)
    const { setCurrentUser } = useContext(CurrentUserContext)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [response, setResponse] = useState({ icon: null, message: null })

    const handleCancel = async () => {
        try {
            setIsSubmitting(true)
            const {
                data: {
                    data: { email, token },
                    detail,
                },
            } = await getEntityService(
                `${ENTITIES.USER}/${ENTITIES.DECLINE_EMAIL_CHANGE}/${hash}`,
                {},
                false
            )

            if (getUserToken()) {
                setUserToken(token)
                setCurrentUser((state) => ({ ...state, email }))
            }
            setResponse({ message: t(detail), icon: ICONS.ALERT_SUCCESS })
        } catch (error) {
            if (error.response?.data?.message) {
                setResponse({
                    message: t(error.response?.data?.message),
                    icon: ICONS.ALERT_ERROR,
                })
            } else {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    const goToApp = () => {
        navigate(ROUTES.HOME)
    }

    return (
        <div className="m-emailLink">
            <div className="m-emailLink__background">
                <div className="m-emailLink__logo" onClick={goToApp}></div>
                <div className="m-emailLink__content">
                    <div className="column aligned-center">
                        <Icon
                            name={response.icon || ICONS.ALERT_WARNING}
                            size={ICON_SIZE.SIZE36}
                            color={COLORS.SECONDARY}
                        />
                        <h6 className="a-bodyTextBold a-centeredText -mt20">
                            {t('general.cancelEmailAddressChange')}
                        </h6>
                        <p className="m-emailLink__text a-centeredText -mt10 -mb20">
                            {response.message ||
                                t(
                                    'general.cancelEmailAddressChangeDescription'
                                )}
                        </p>
                        {response.message ? (
                            <Button
                                label="button.goToApp"
                                btnClass={BUTTON_STATUS.TERTIARY}
                                buttonSize={BUTTON_SIZE.LARGE}
                                icon={ICONS.ARROW_RIGHT}
                                iconPosition={ICON_POSITION.RIGHT}
                                iconColor={COLORS.SECONDARY}
                                onClick={goToApp}
                            />
                        ) : (
                            <Button
                                label="button.cancel"
                                buttonSize={BUTTON_SIZE.LARGE}
                                onClick={handleCancel}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isSubmitting && <Loader />}
        </div>
    )
}

export default DeclineEmailChange
