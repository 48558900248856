const COLORS = {
    //Primary colors:
    DARK_BLUE: '#171e3c',
    DARK_BLUE_02: '#fafbfb',
    DARK_BLUE_05: '#f3f4f5',
    DARK_BLUE_10: '#ebecf1',
    DARK_BLUE_15: '#dcdde2',
    DARK_BLUE_20: '#d1d2d8',
    DARK_BLUE_40: '#a2a5b1',
    DARK_BLUE_60: '#74788a',
    DARK_BLUE_80: '#454b63',
    LIGHT_BLUE: '#0177a9',
    LIGHT_BLUE_05: '#f2f8fb',
    LIGHT_BLUE_20: '#d6e9f1',
    LIGHT_BLUE_60: '#67adcb',
    LIGHT_BLUE_80: '#3492ba',
    WHITE: '#ffffff',
    //Secondary colors:
    GREEN: '#008a5c',
    GREEN_10: '#e6f3ef',
    GREEN_30: '#b3dcce',
    GREEN_60: '#66b99d',
    GREEN_80: '#33a17d',
    ORANGE: '#fb5607',
    ORANGE_10: '#ffeee6',
    ORANGE_30: '#feccb5',
    ORANGE_60: '#fd9a6a',
    ORANGE_80: '#fc7839',
    RED: '#e50004',
    RED_80: '#eb3336',
    RED_60: '#f06668',
    RED_30: '#f7b3b4',
    RED_10: '#fce6e6',
    PURPLE: '#692c93',
    PURPLE_80: '#8756a9',
    PURPLE_60: '#a580be',
    PURPLE_30: '#d2c0df',
    PURPLE_10: '#f0eaf4',
    //Custom colors:
    TRANSPARENT: 'transparent',
    DEEP_GREEN: '#0177a9',
    SILVER: '#f3f4f5',
    DARK_BLUE_CUSTOM: '#131574',
    ORANGE_CUSTOM: '#ffc634',
    ORANGE_CUSTOM_2: '#ffe39c',
    ORANGE_CUSTOM_3: '#fff5cf',
    ORANGE_CUSTOM_4: '#e9a655',
    ORANGE_CUSTOM_5: '#fbb02f',
    ORANGE_CUSTOM_6: '#fcd149',
    ORANGE_CUSTOM_7: '#e98736',
    ORANGE_CUSTOM_8: '#e9a654',
}
export default COLORS
