import React, { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { editEntityService } from 'services/entity.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import { DATA_ACCESS_TYPES } from 'constants/constants'
import {
    ACCESS_CONTROL_STATUSES,
    ALERT_TYPES,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import RadioGroup from 'components/RadioGroup'
import { Table } from 'components/table'
import StatusCell from 'components/table/tableCells/StatusCell'
import UserWithRoleCell from 'components/table/tableCells/UserWithRoleCell'
import AccessControlActions from './AccessControlActions'
import NoData from 'components/NoData'

const TABLE_HEADER = [
    {
        key: 'requester',
        title: 'table.header.user',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        sortable: true,
        CellComponent: UserWithRoleCell,
    },
    {
        key: 'status',
        title: 'form.label.status',
        showOnSmallScreens: true,
        smallScreenHeader: true,
        sortable: true,
        CellComponent: StatusCell,
    },
]

const DataAccessControl = ({
    module,
    accessTypeLabel,
    dataAccessAttribute,
    title,
    noFiles,
}) => {
    const t = useTranslate()
    const { search } = useLocation()

    const { currentUser, userId, fetchCurrentUser } =
        useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const [isPublicAccess, setIsPublicAccess] = useState(
        currentUser.userSetting[dataAccessAttribute]
    )

    const { data, meta, isLoading, fetchData } = useFetchDataByQueryParams(
        ENTITIES.DATA_ACCESS_REQUEST,
        {
            include: 'requester,requester.companies,target',
            'target.id': userId,
            module,
        }
    )

    const tableData =
        data?.map((item) => ({
            ...item,
            status:
                noFiles &&
                item.status === ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id
                    ? ACCESS_CONTROL_STATUSES.GRANTED.id
                    : item.status,
        })) || []

    const handleChangeAccess = async (value) => {
        try {
            setIsPublicAccess(value)
            await editEntityService(
                ENTITIES.USER_SETTING,
                currentUser.userSetting.id,
                { [dataAccessAttribute]: value }
            )
            fetchCurrentUser()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <Fragment>
            <span className="-mb20 a-bodyTextMedium">
                {t(`${title ? title : 'general.dataAccessControl'}`)}
            </span>
            <div className="aligned-start">
                <RadioGroup
                    name="isPublicAccess"
                    label={accessTypeLabel}
                    defaultOptions={DATA_ACCESS_TYPES}
                    valueType={SELECT_VALUE_TYPE.STRING}
                    value={isPublicAccess}
                    setValue={handleChangeAccess}
                />
            </div>
            <span className="a-mediumText a-lightText -mt5">
                {t(
                    isPublicAccess
                        ? 'general.publicAddressDescription'
                        : 'general.selectiveAccessDescription'
                )}
            </span>
            {data?.length === 0 && !isLoading && !search && (
                <div className="-mt20">
                    <span className="a-bodyTextMedium -title">
                        {t('general.requests')}
                    </span>

                    <div className="-mt50">
                        <NoData
                            icon={ICONS.NO_DATA}
                            title={t('general.noDataYet')}
                            description={t('general.nothingHereRightNow')}
                        />
                    </div>
                </div>
            )}
            {!isPublicAccess && (search || data?.length > 0) && (
                <div className="-mt20">
                    <Table
                        title="general.requests"
                        headerItems={TABLE_HEADER}
                        data={tableData}
                        isLoading={isLoading}
                        totalItems={meta?.totalItems}
                        rowActions={[
                            {
                                ActionComponent: AccessControlActions,
                                fetchData: fetchData,
                                noFiles: noFiles,
                            },
                        ]}
                        searchable
                        fullWidthTitle
                    />
                </div>
            )}
        </Fragment>
    )
}

DataAccessControl.propTypes = {
    module: PropTypes.number.isRequired,
    accessTypeLabel: PropTypes.string.isRequired,
    dataAccessAttribute: PropTypes.string.isRequired,
}

export default DataAccessControl
