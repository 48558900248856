import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import { deleteEntityService } from 'services/entity.service'
import { checkExpiryDate, formatDate } from 'services/moment.service'

import { refetchProfile } from '../../utils/fetchHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import { MEDICAL_EXAM_TYPE, MEDICAL_RECORD_TYPE } from '../../constants'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'
import InfoRow from 'components/InfoRow'
import EditMedicalRecord from '../EditMedicalRecord'
import CardHeader from 'components/card/CardHeader'

const MedicalRecords = ({
    data,
    fetchMedicalRecords,
    isLoading,
    profilePage,
    showActions,
    refetchData,
    filteredData,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const [edit, setEdit] = useState(null)

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleConfirm = async (item) => {
        try {
            await deleteEntityService(ENTITIES.MEDICAL_RECORD, item.id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchMedicalRecords()

            const condition = [
                MEDICAL_RECORD_TYPE.ANNUAL.id,
                MEDICAL_RECORD_TYPE.BIENNIAL.id,
            ].includes(item?.medicalRecordType.id)

            refetchData &&
                refetchProfile(
                    condition,
                    filteredData,
                    refetchData,
                    false,
                    true
                )
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = (item) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteMedicalRecord',
            title: 'general.deleteMedicalRecord',
            handleConfirm: () => handleConfirm(item),
            handleCancel,
        })

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={profilePage ? 1 : 2} />
    }

    return (
        <div className="_wr">
            <div className="_w">
                {data.map((item) => (
                    <div
                        key={item.id}
                        className={`${
                            profilePage ? '_l12 profileWrapper' : '_l6'
                        } _12 -mt20`}
                    >
                        <CardContainer>
                            <CardHeader
                                title={`${item.medicalRecordType.name} medical examination`}
                                statuses={
                                    item?.medicalExam?.id ===
                                    MEDICAL_EXAM_TYPE.DIVER_MEDICAL_EXAM.id
                                        ? [
                                              {
                                                  name: `Declared ${
                                                      item.fitToDive
                                                          ? 'FIT'
                                                          : 'UNFIT'
                                                  } to dive`,
                                                  color: item.fitToDive
                                                      ? 'green'
                                                      : 'red',
                                              },
                                          ]
                                        : []
                                }
                                item={item}
                                actions={
                                    showActions
                                        ? [
                                              {
                                                  handleAction: handleEdit,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  tooltip:
                                                      'button.editMedicalRecord',
                                              },
                                              {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  tooltip:
                                                      'button.deleteMedicalRecord',
                                              },
                                          ]
                                        : []
                                }
                                fullTitle
                            />
                            <Info
                                label="form.label.medicalExam"
                                value={item?.medicalExam?.name}
                            />
                            <Info
                                label="form.label.nameOfMedicalFacility"
                                value={item.medicalFacility}
                            />
                            <Info
                                label="form.label.medicalFacilityAddress"
                                value={item.medicalFacilityAddress}
                            />
                            <Info
                                label="form.label.nameOfTheDoctor"
                                value={item.doctorName}
                            />
                            {item?.medicalExamStandard && (
                                <Info
                                    label="form.label.medicalExamStandard"
                                    value={item.medicalExamStandard.name}
                                />
                            )}
                            <InfoRow>
                                <Info
                                    label="form.label.dateOfExam"
                                    value={formatDate(item.dateOfExam)}
                                />
                                {item.dateOfExpiatory && (
                                    <Info
                                        label="form.label.dateOfExpiatory"
                                        value={formatDate(item.dateOfExpiatory)}
                                        textColor={checkExpiryDate(
                                            item.dateOfExpiatory
                                        )}
                                    />
                                )}
                                {!item.dateOfExpiatory && (
                                    <Info
                                        label="form.label.dateOfExpiatory"
                                        value={t('form.label.noExpirationDate')}
                                    />
                                )}
                            </InfoRow>
                            <Info
                                label="general.restrictions"
                                value={
                                    item.restrictionsNote ||
                                    t('general.noRestrictions')
                                }
                                color={
                                    item.restrictionsNote ? 'orange' : 'green'
                                }
                            />
                            <Info
                                label="general.routineMedications"
                                value={
                                    item.routineMedicationsNote ||
                                    t('general.noRoutineMedications')
                                }
                                color={
                                    item.routineMedicationsNote
                                        ? 'orange'
                                        : 'green'
                                }
                            />
                            {item?.comment && (
                                <Info
                                    label="form.label.comment"
                                    value={item.comment}
                                    column
                                />
                            )}
                            <InfoFiles
                                label="general.medicalRecordFiles"
                                files={item.files}
                            />
                        </CardContainer>
                    </div>
                ))}

                {!!edit && (
                    <EditMedicalRecord
                        data={edit}
                        setOpen={setEdit}
                        fetchMedicalRecords={fetchMedicalRecords}
                        showActions={showActions}
                        refetchData={refetchData}
                        filteredData={filteredData}
                    />
                )}
            </div>
        </div>
    )
}

MedicalRecords.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchMedicalRecords: PropTypes.func,
    profilePage: PropTypes.bool,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
    filteredData: PropTypes.array,
}

MedicalRecords.defaultProps = {
    profilePage: false,
    showActions: false,
}

export default MedicalRecords
