import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import Icon from 'components/Icon'
import COLORS from 'constants/colors'

import ICONS from 'constants/icons'
import { Link } from 'react-router-dom'
import { ICON_SIZE } from 'constants/enums'

const ContactInfo = ({
    email,
    country,
    phoneNumber,
    dateOfBirth,
    nationality,
    totalExperienceYears,
    user,
}) => {
    const t = useTranslate()

    const originalDate = new Date(dateOfBirth)
    const convertedDate = originalDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    })

    return (
        <div className="-contactInfo aligned-center -mt20">
            {country && (
                <div className="-location">
                    <Icon
                        name={ICONS.LOCATION}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        {country}
                    </span>
                </div>
            )}
            <div className="-email">
                <Icon
                    name={ICONS.MAIL}
                    size={ICON_SIZE.SIZE16}
                    color={COLORS.DARK_BLUE_20}
                />
                <Link
                    className="a-mediumText a-lightText -opacity-60"
                    to=""
                    onClick={() => (window.location = `mailto:${email}`)}
                    title={email}
                >
                    {email}
                </Link>
            </div>
            {phoneNumber && (
                <div className="-phone">
                    <Icon
                        name={ICONS.PHONE}
                        color={COLORS.DARK_BLUE_20}
                        size={ICON_SIZE.SIZE18}
                    />
                    <Link
                        className="a-mediumText a-lightText -opacity-60"
                        to=""
                        onClick={() => (window.location = `tel:${phoneNumber}`)}
                        title={phoneNumber}
                    >
                        {phoneNumber}
                    </Link>
                </div>
            )}
            {dateOfBirth && (
                <div className="-dateOfBirth">
                    <Icon
                        name={ICONS.CALENDAR}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        {convertedDate}
                    </span>
                </div>
            )}
            {nationality && (
                <div className="-nationality">
                    <Icon
                        name={ICONS.GLOBE}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        {nationality}
                    </span>
                </div>
            )}

            {totalExperienceYears && (
                <div className="-totalYears">
                    <Icon
                        name={ICONS.SKILLS}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        {totalExperienceYears} {t('general.yearsOfExperience')}
                    </span>
                </div>
            )}

            <div className="-totalYears">
                <Icon
                    name={ICONS.DIVER}
                    size={ICON_SIZE.SIZE20}
                    color={COLORS.DARK_BLUE_20}
                />
                <span className="a-mediumText a-lightText -opacity-60">
                    {user.numberOfDives} {t('general.dives')}
                </span>
            </div>
        </div>
    )
}

ContactInfo.propTypes = {
    email: PropTypes.string,
    country: PropTypes.string,
    phoneNumber: PropTypes.string,
    dateOfBirth: PropTypes.string,
    nationality: PropTypes.string,
    totalExperienceYears: PropTypes.number,
}

export default ContactInfo
