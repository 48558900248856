import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'
import useFetchData from 'hooks/useFetchData'

import { AlertContext } from 'contexts/AlertContext'
import { NotificationContext } from 'contexts/NotificationContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { editService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'

import Dropdown from 'components/Dropdown'
import IconButton from 'components/IconButton'
import NotificationItem from './NotificationItem'
import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import NoData from 'components/NoData'

const NotificationsDropdown = ({ open, setOpen }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    const { setAlert } = useContext(AlertContext)
    const { isVerified } = useContext(CurrentUserContext)
    const {
        unreadCount,
        unreadNotifications,
        setUnreadCount,
        setReloadUnreadNotifications,
    } = useContext(NotificationContext)

    const [displayNotifications, setDisplayNotifications] = useState([])

    const {
        data: notifications,
        meta,
        isLoading: isLoadingNotifications,
        loadMore: loadMoreNotifications,
        fetchData: fetchNotifications,
        isReady,
    } = useFetchData(ENTITIES.NOTIFICATIONS, { include: 'user' }, open)

    const handleOpenNotifications = () => {
        setOpen(!open)
    }

    const handleReadAll = async () => {
        if (!!unreadCount) {
            try {
                await editService(ENTITIES.MARK_READ, { seen: true })
                fetchNotifications()
                setReloadUnreadNotifications((state) => !state)

                const notificationsCopy = [...displayNotifications]

                notificationsCopy.forEach(
                    (notification) => (notification.seen = true)
                )

                setDisplayNotifications(notificationsCopy)
            } catch (error) {
                setAlert(error, ALERT_TYPES.ERROR)
            }
        }
    }

    // NOTE:: for later
    // const handleOpenSettings = () => {
    //     console.log('Handle open settings')
    // }

    const handleLoadMore = () => {
        if (!isLoadingNotifications && loadMoreNotifications) {
            fetchNotifications({
                page: meta?.currentPage + 1,
            })
        }
    }

    useEffect(() => {
        setDisplayNotifications([
            ...displayNotifications,
            ...notifications?.filter(
                (item) => !displayNotifications.some((el) => el.id === item.id)
            ),
        ])
        if (meta?.totalNumberOfUnread !== undefined) {
            setUnreadCount(meta?.totalNumberOfUnread)
        }
    }, [notifications])

    useEffect(() => {
        setDisplayNotifications([
            ...unreadNotifications?.filter(
                (item) => !displayNotifications.some((el) => el.id === item.id)
            ),
            ...displayNotifications,
        ])
    }, [unreadNotifications])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!open) {
                setDisplayNotifications([])
            }
        }, 3000)

        if (open) {
            setDisplayNotifications(notifications)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [open, notifications])

    useEffect(() => {
        if (open) {
            fetchNotifications()
        }

        if (open && isTablet) {
            document.body.style.overflow = 'hidden'
            return () => {
                document.body.style.overflow = 'unset'
            }
        }
    }, [open])

    const showPlus = unreadCount > 9

    return (
        <div className="-dropdownWrapper">
            <IconButton
                icon={ICONS.NOTIFICATIONS_NO_CIRCLE}
                size={ICON_SIZE.SIZE24}
                onClick={handleOpenNotifications}
            />
            {unreadCount > 0 && (
                <div
                    className="countBadge justify-center-button"
                    onClick={handleOpenNotifications}
                >
                    {showPlus ? 9 : unreadCount}
                    {showPlus && <span className="plus">+</span>}
                </div>
            )}
            <Dropdown
                open={open && isReady}
                notificationDropdown
                handleReachedBottom={handleLoadMore}
            >
                <div className="m-dropdown__heading">
                    <span className="a-mediumTextSemiBold">{`${t(
                        'general.notifications'
                    )} (${unreadCount})`}</span>
                    <div>
                        <span
                            onClick={handleReadAll}
                            className={`a-captionsTextRegular -pointer ${
                                !!unreadCount ? '' : '-disabled'
                            }`}
                        >
                            {t('general.markAllAsRead')}
                        </span>
                        {/* NOTE: hidden for now  */}
                        {/* <IconButton
                            icon={ICONS.SETTINGS}
                            size={ICON_SIZE.SIZE22}
                            onClick={handleOpenSettings}
                        /> */}
                    </div>
                </div>

                {displayNotifications.length > 0 ? (
                    <NotificationItem
                        displayNotifications={displayNotifications}
                        setDisplayNotifications={setDisplayNotifications}
                        handleOpenNotifications={handleOpenNotifications}
                        fetchNotifications={fetchNotifications}
                        isVerified={isVerified}
                    />
                ) : (
                    <NoData
                        icon={ICONS.BELL}
                        title={t('general.noNotificationsYet')}
                        description={t('general.noNotificationsDesc')}
                    />
                )}
            </Dropdown>
        </div>
    )
}

NotificationsDropdown.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
}

export default clickOutsideHOC(NotificationsDropdown)
