import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { editEntityService } from 'services/entity.service'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'
import ROUTES from 'constants/routes'
import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { SelectField } from 'components/formFields'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { UNITS_OF_MEASUREMENT } from 'constants/constants'
import FocusError from '../../../../components/FocusError'

const UnitOfMeasurementForm = () => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { projectId } = useParams()

    const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        unitImperial: null,
    }

    const validation = Yup.object({
        unitImperial: Yup.object().required(t('form.error.required')),
    })

    const handleSubmit = async ({ unitImperial }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await editEntityService(
                ENTITIES.USER_SETTING,
                currentUser.userSetting.id,
                { unitImperial: Boolean(unitImperial.id) }
            )
            fetchCurrentUser()
            window.location.reload()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    // NOTE:: issue with go back and cancel button navigation
    const handleCancel = () => {
        projectId
            ? navigate(`${ROUTES.PROJECT}/${projectId}`)
            : navigate(`/${ROUTES.DIVE_RECORDS}`)
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="-modalElements">
                    <FocusError />
                    <Modal
                        closeOnClickOutside={false}
                        type="-confirmModal -smallModal"
                        title="general.selectUnitsOfMeasurement"
                        isSubmitting={isSubmitting}
                        buttons={{
                            prevBtn: {
                                handleClick: handleCancel,
                            },
                        }}
                        smallModalWithDropdowns
                    >
                        <p className="a-bodyTextRegular -mt20 _12 a-centeredText">
                            {t('general.unitsOfMeasurementDescription')}
                        </p>
                        <div className="_12 -mt30">
                            <SelectField
                                name="unitImperial"
                                label="form.label.unitImperial"
                                placeholder="form.placeholder.pleaseSelect"
                                defaultOptions={UNITS_OF_MEASUREMENT}
                                required
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default UnitOfMeasurementForm
