import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import useWindowDimensions from 'hooks/useWindowDimension'
import useFetchData from 'hooks/useFetchData'

import ENTITIES from 'constants/entities'
import { TOOLTIP_POSITION } from 'constants/enums'
import ROUTES from 'constants/routes'

import About from './About'
import ContactInfo from './ContactInfo'
import Experience from './Experience'
import Badge from 'components/Badge'
import VerificationLevels from './VerificationLevels'
import BadgeTooltip from 'components/BadgeTooltip'
import BadgeTooltipContent from 'components/BadgeTooltipContent'
import useBadgeData from 'screens/common/helpAndInfo/data/Badges'

const PersonalInfoCard = ({ user }) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const badgesRef = useRef(null)

    const { isSmallScreen, isTablet } = useWindowDimensions()

    const {
        id,
        country,
        email,
        phoneNumber,
        dateOfBirth,
        nationality,
        totalExperienceYears,
    } = user

    const {
        BADGES,
        JOB_QUALIFICATION_BADGES,
        SPECIALIZED_DIVING_SKILLS_BADGES,
    } = useBadgeData()

    const allBadges = [
        ...BADGES,
        ...JOB_QUALIFICATION_BADGES,
        ...SPECIALIZED_DIVING_SKILLS_BADGES,
    ]

    const { data: userBadges, isReady } = useFetchData(
        ENTITIES.USER_BADGES,
        {
            'user.id': id,
        },
        true,
        false,
        id
    )

    const handleFindOutMore = (badge) => {
        const badgeCategory = badge.category

        navigate(
            `/${ROUTES.HELP_AND_INFO}/${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${
                ROUTES.BADGES
            }?subTab=${badgeCategory}&badgeId=${`badge-${badge.key}`}`
        )
    }

    if (!isReady) return null

    return (
        <div className="m-userMainInfo">
            <div className="m-userMainInfo__mainInfo">
                <div className="m-userMainInfo__mainInfo--contactInfo">
                    <div className="aligned-center a-tabName">
                        <span className="a-bodyTextMedium ">
                            {t('general.personalInfo')}
                        </span>
                    </div>
                    <ContactInfo
                        phoneNumber={phoneNumber}
                        country={country}
                        email={email}
                        dateOfBirth={dateOfBirth}
                        nationality={nationality}
                        totalExperienceYears={totalExperienceYears}
                        user={user}
                        isSmallScreen={isSmallScreen}
                    />
                </div>
            </div>

            {user.about && (
                <div className="column">
                    <span className="a-mediumText -mt15 -mb10">
                        {t('form.label.aboutMe')}
                    </span>
                    <p className="a-mediumText a-lightText -opacity-60 -about">
                        {user.about}
                    </p>
                </div>
            )}

            {user.professionalSummary && (
                <div className="column">
                    <span className="a-mediumText -mt15 -mb10">
                        {t('general.professionalSummary')}
                    </span>
                    <p className="a-mediumText a-lightText -opacity-60 -about">
                        {user.professionalSummary}
                    </p>
                </div>
            )}
            {!!userBadges.length && (
                <div className="-badges -mt20 aligned-center" ref={badgesRef}>
                    {userBadges.map(
                        ({ id, additionalBadgeKey, badgeKey }, index) => (
                            <div key={id}>
                                <BadgeTooltip
                                    position={TOOLTIP_POSITION.RIGHT}
                                    tooltip={
                                        <BadgeTooltipContent
                                            badge={allBadges.find(
                                                (item) =>
                                                    item.key ===
                                                    (additionalBadgeKey ||
                                                        badgeKey)
                                            )}
                                            size={100}
                                            additionalBadgeKey={
                                                additionalBadgeKey
                                            }
                                            badgeKey={badgeKey}
                                            id={id}
                                        />
                                    }
                                    badgesRef={badgesRef}
                                >
                                    <div
                                        onClick={() =>
                                            isTablet
                                                ? handleFindOutMore(
                                                      allBadges.find(
                                                          (item) =>
                                                              item.key ===
                                                              (additionalBadgeKey ||
                                                                  badgeKey)
                                                      )
                                                  )
                                                : () => {}
                                        }
                                    >
                                        <Badge
                                            key={id}
                                            badgeKey={badgeKey}
                                            additionalBadgeKey={
                                                additionalBadgeKey
                                            }
                                        />
                                    </div>
                                </BadgeTooltip>
                            </div>
                        )
                    )}
                </div>
            )}
            <VerificationLevels user={user} />
            <About user={user} isSmallScreen={isSmallScreen} />
            <Experience user={user} isSmallScreen={isSmallScreen} />
        </div>
    )
}

PersonalInfoCard.propTypes = {
    user: PropTypes.object,
    myProfile: PropTypes.bool,
    setOpen: PropTypes.func,
}

PersonalInfoCard.defaultProps = {
    myProfile: false,
}

export default PersonalInfoCard
