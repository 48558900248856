import ROUTES from 'constants/routes'
import { ROLES } from 'constants/enums'

import {
    AdminLayout,
    AuthLayout,
    FullScreenLayout,
    NoAuthLayout,
} from 'components/layouts'
import { MyDiveProjects } from 'screens/diver/MyDiveProjects'
import {
    DiveProfileRecord,
    DiveRecord,
    DiveSummaryRecord,
    EquipmentRecord,
    GeneralInformationRecord,
    WorkPerformedRecord,
    RecordVerification,
    GeneralInformationVerification,
    EquipmentVerification,
    DiveProfileVerification,
    WorkPerformedVerification,
    DiveSummaryVerification,
} from 'screens/diver/diveRecord'
import {
    DeclineEmailChange,
    MedicalInfoAccessControl,
    Notifications,
    PastEmploymentAccessControl,
    PersonalEquipmentAccessControl,
    Settings,
    SignInAndSecurity,
    TrainingRecordsAccessControl,
    TravelDocumentsAccessControl,
    UnitsAndFormats,
} from 'screens/diver/settings'
import MyDiveRecords from 'screens/diver/myDiveRecords/MyDiveRecords'
import DiveRecordSummary from 'screens/diver/DiveRecordSummary'
import { GalleryPage } from 'screens/common/gallery'
import { ChatPage } from 'screens/common/chat/ChatPage'
import { Homepage as DiverHomepage } from 'screens/diver/Homepage'
import {
    CreateProject,
    Homepage as DivingContractorHomepage,
    EditProject,
    ProjectDiveTeam,
} from 'screens/divingContractor'
import { NotFoundPage, SingleProject } from 'screens/common'
import ProjectDiveRecords from 'screens/common/projectDiveRecords/ProjectDiveRecords'
import { ProfileScreen as DiverProfile } from 'screens/diver/profile'
import { ProfileScreen as ContractorProfile } from 'screens/divingContractor'
import {
    DiveRecordVerificationLevels,
    HelpAndInfo,
} from 'screens/common/helpAndInfo'
import Release090 from '../../screens/common/helpAndInfo/release/Release090'
import Release091 from '../../screens/common/helpAndInfo/release/Release091'
import Release100 from 'screens/common/helpAndInfo/release/Release100'
import Release110 from 'screens/common/helpAndInfo/release/Release110'
import Register from 'screens/noAuth/Register'
import SkillsManagement from 'screens/admin/SkillsManagement'
import GeneralPreferences from 'screens/diver/settings/GeneralPreferences'
import DiveRecordsAccessControl from 'screens/diver/settings/DiveRecordsAccessControl'
import ExportCvAccessControl from 'screens/diver/settings/ExportCvAccessControl'
import AccountManagement from 'screens/diver/settings/accountManagement/AccountManagement'
import Badges from 'screens/common/helpAndInfo/Badges'
import CreateAProject from 'screens/common/helpAndInfo/guids/CreateAProject'
import CreateHistoricalDiveRecord from 'screens/common/helpAndInfo/guids/CreateHistoricalDiveRecord'
import CreatePersonalAccount from 'screens/common/helpAndInfo/guids/CreatePersonalAccount'
import SendDiveRecordForVerificationLevelOne from 'screens/common/helpAndInfo/guids/SendDiveRecordForVerificationLevelOne'
import SignUpAsOrganization from 'screens/common/helpAndInfo/guids/SignUpAsOrganization'
import UpdateOrganizationProfile from 'screens/common/helpAndInfo/guids/UpdateOrganizationProfile'
import UpdatePersonalInformation from 'screens/common/helpAndInfo/guids/UpdatePersonalInformation'
import VerifyDiveRecordAsASupervisorLevelOne from 'screens/common/helpAndInfo/guids/VerifyDiveRecordAsASupervisorLevelOne'
import VerifyIdentityUsingPersona from 'screens/common/helpAndInfo/guids/VerifyIdentityUsingPersona'

const { ROLE_DIVER, ROLE_SUPER_ADMIN, ROLE_DIVING_CONTRACTOR } = ROLES

const diveRecordRoutes = [
    {
        path: ROUTES.GENERAL_INFORMATION,
        element: <GeneralInformationRecord />,
    },
    {
        path: ROUTES.EQUIPMENT,
        element: <EquipmentRecord />,
    },
    {
        path: ROUTES.DIVE_PROFILE,
        element: <DiveProfileRecord />,
    },
    {
        path: ROUTES.WORK_PERFORMED,
        element: <WorkPerformedRecord />,
    },
    {
        path: ROUTES.DIVE_SUMMARY,
        element: <DiveSummaryRecord />,
    },
]

const diveRecordVerificationRoutes = [
    {
        path: ROUTES.GENERAL_INFORMATION,
        element: <GeneralInformationVerification />,
    },
    {
        path: ROUTES.EQUIPMENT,
        element: <EquipmentVerification />,
    },
    {
        path: ROUTES.DIVE_PROFILE,
        element: <DiveProfileVerification />,
    },
    {
        path: ROUTES.WORK_PERFORMED,
        element: <WorkPerformedVerification />,
    },
    {
        path: ROUTES.DIVE_SUMMARY,
        element: <DiveSummaryVerification />,
    },
    {
        path: ROUTES.VERIFICATION,
        element: <RecordVerification />,
    },
]

export const historicalDiveRecordVerificationRoute = {
    path: `${ROUTES.DIVE_RECORD_SUMMARY}/:verificationToken`,
    element: <DiveRecord />,
    routes: diveRecordVerificationRoutes,
}

const authRoutes = [
    {
        element: <AuthLayout />,
        roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
        layout: true,
        routes: [
            {
                path: ROUTES.HOME,
                element: <DiverHomepage />,
                roles: [ROLE_DIVER],
            },
            {
                path: ROUTES.HOME,
                element: <DivingContractorHomepage />,
                roles: [ROLE_DIVING_CONTRACTOR],
            },
            {
                path: ROUTES.MY_DIVE_PROJECTS,
                element: <MyDiveProjects />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
            },
            {
                path: ROUTES.DIVE_RECORDS,
                element: <MyDiveRecords />,
                roles: [ROLE_DIVER],
            },
            {
                path: ROUTES.SETTINGS,
                element: <Settings />,
                roles: [ROLE_DIVER],
                routes: [
                    {
                        path: ROUTES.UNITS_OF_MEASUREMENT,
                        element: <UnitsAndFormats />,
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.DIVE_RECORDS}`,
                        element: <DiveRecordsAccessControl />,
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.MEDICAL_INFO}`,
                        element: <MedicalInfoAccessControl />,
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.TRAVEL_DOCUMENTATION}`,
                        element: <TravelDocumentsAccessControl />,
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.TRAINING_RECORDS}`,
                        element: <TrainingRecordsAccessControl />,
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.PERSONAL_EQUIPMENT}`,
                        element: <PersonalEquipmentAccessControl />,
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.PAST_EMPLOYMENT}`,
                        element: <PastEmploymentAccessControl />,
                    },
                    {
                        path: ROUTES.GENERAL_PREFERENCES,
                        element: <GeneralPreferences />,
                    },
                    {
                        path: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.EXPORT_CV}`,
                        element: <ExportCvAccessControl />,
                    },
                    {
                        path: ROUTES.ACCOUNT_MANAGEMENT,
                        element: <AccountManagement />,
                    },
                    {
                        path: ROUTES.SIGN_IN_AND_SECURITY,
                        element: <SignInAndSecurity />,
                    },
                    {
                        path: ROUTES.NOTIFICATIONS,
                        element: <Notifications />,
                    },
                ],
            },
            {
                path: `${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORDS}/:diveRecordId`,
                element: <DiveRecordSummary />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.DIVE_RECORDS}/:diveRecordId`,
                element: <DiveRecordSummary />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
            },
            {
                path: ROUTES.GALLERY,
                element: <GalleryPage />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
            },
            {
                path: ROUTES.CHAT,
                element: <ChatPage />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.PROJECT}/:id`,
                element: <SingleProject />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.PROJECT}/:id/${ROUTES.DIVE_RECORDS}`,
                element: <ProjectDiveRecords />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}/:id`,
                element: <SingleProject />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}/:id/${ROUTES.MY_PROJECT_RECORDS}`,
                element: <ProjectDiveRecords />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.DIVER_PROFILE}/:profileHash`,
                element: <DiverProfile />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
            },
            {
                path: `${ROUTES.DIVING_CONTRACTOR_PROFILE}/:profileHash`,
                element: <ContractorProfile />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                requireVerification: true,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}/:projectId/${ROUTES.MY_PROJECT_RECORDS}/:diveRecordId`,
                element: <DiveRecordSummary />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
            },
            {
                path: `${ROUTES.SETTINGS}/${ROUTES.ACCOUNT_MANAGEMENT}`,
                element: <AccountManagement />,
                roles: [ROLE_DIVER],
            },
            {
                path: ROUTES.HELP_AND_INFO,
                element: <HelpAndInfo />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                routes: [
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.CREATE_A_PROJECT}`,
                        element: <CreateAProject />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.CREATE_HISTORICAL_DIVE_RECORD}`,
                        element: <CreateHistoricalDiveRecord />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.CREATE_PERSONAL_ACCOUNT}`,
                        element: <CreatePersonalAccount />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.SEND_DIVE_RECORD_FOR_VERIFICATION_LEVEL_ONE}`,
                        element: <SendDiveRecordForVerificationLevelOne />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.SIGN_UP_AS_ORGANIZATION}`,
                        element: <SignUpAsOrganization />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.UPDATE_ORGANIZATION_PROFILE}`,
                        element: <UpdateOrganizationProfile />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.UPDATE_PERSONAL_INFORMATION}`,
                        element: <UpdatePersonalInformation />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.VERIFY_DIVE_RECORD_AS_A_SUPERVISOR_LEVEL_ONE}`,
                        element: <VerifyDiveRecordAsASupervisorLevelOne />,
                    },
                    {
                        path: `${ROUTES.HOW_TO}/${ROUTES.VERIFY_IDENTITY_USING_PERSONA}`,
                        element: <VerifyIdentityUsingPersona />,
                    },
                    {
                        path: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`,
                        element: <DiveRecordVerificationLevels />,
                    },

                    {
                        path: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.BADGES}`,
                        element: <Badges />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_0}`,
                        element: <Release090 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_1}`,
                        element: <Release091 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_0_0}`,
                        element: <Release100 />,
                    },
                    {
                        path: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_1_0}`,
                        element: <Release110 />,
                    },
                ],
            },
            {
                path: `${ROUTES.PROJECT}/:id${ROUTES.DIVE_TEAM}`,
                element: <ProjectDiveTeam />,
                roles: [ROLE_DIVING_CONTRACTOR],
            },
            {
                path: `${ROUTES.PROJECT}${ROUTES.CREATE}`,
                element: <CreateProject />,
                roles: [ROLE_DIVING_CONTRACTOR],
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}${ROUTES.CREATE}`,
                element: <CreateProject />,
                roles: [ROLE_DIVING_CONTRACTOR],
            },
            {
                path: `${ROUTES.PROJECT}${ROUTES.EDIT}/:id`,
                element: <EditProject />,
                roles: [ROLE_DIVING_CONTRACTOR],
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}${ROUTES.EDIT}/:id`,
                element: <EditProject />,
                roles: [ROLE_DIVING_CONTRACTOR],
            },
        ],
    },
    {
        element: <FullScreenLayout />,
        layout: true,
        routes: [
            {
                path: `${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORD}/:diveRecordId?`,
                element: <DiveRecord />,
                roles: [ROLE_DIVER],
                requireVerification: true,
                routes: diveRecordRoutes,
            },
            {
                path: `${ROUTES.MY_DIVE_PROJECTS}/:projectId/${ROUTES.DIVE_RECORD}/:diveRecordId?`,
                element: <DiveRecord />,
                roles: [ROLE_DIVER],
                requireVerification: true,
                routes: diveRecordRoutes,
            },
            {
                path: `/${ROUTES.DIVE_RECORD}/:diveRecordId?`,
                element: <DiveRecord />,
                roles: [ROLE_DIVER],
                routes: diveRecordRoutes,
            },
            {
                path: `${ROUTES.PROJECT}/:projectId/${ROUTES.DIVE_RECORD_SUMMARY}/:diveRecordId`,
                element: <DiveRecord />,
                roles: [ROLE_DIVER],
                requireVerification: true,
                routes: diveRecordVerificationRoutes,
            },
            {
                ...historicalDiveRecordVerificationRoute,
                roles: [ROLE_DIVER],
            },
            {
                path: `${ROUTES.DECLINE_EMAIL_CHANGE}/:hash`,
                element: <DeclineEmailChange />,
                roles: [ROLE_DIVER],
            },
            { path: ROUTES.NOT_FOUND_PAGE, element: <NotFoundPage /> },
        ],
    },
    {
        element: <NoAuthLayout />,
        roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
        layout: true,
        routes: [
            {
                path: ROUTES.REGISTER,
                element: <Register />,
                roles: [ROLE_DIVER, ROLE_DIVING_CONTRACTOR],
                onboarding: true,
            },
        ],
    },
    {
        element: <AdminLayout />,
        roles: [ROLE_SUPER_ADMIN],
        layout: true,
        routes: [
            {
                path: ROUTES.HOME,
                element: <SkillsManagement />,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                path: ROUTES.SKILLS_MANAGEMENT,
                element: <SkillsManagement />,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
]

export { authRoutes }
