import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import {
    MOMENT_FORMATS,
    getIsDateSameOrBefore,
    getTomorrowDate,
} from 'services/moment.service'

import useQueryParams from 'hooks/useQueryParams'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { validateGteLteValues } from 'utils/yupValidations'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import { ICON_SIZE, INPUT_FILED_TYPE } from 'constants/enums'
import ICONS from 'constants/icons'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import {
    ArrayCustomGroupField,
    DateTimeField,
    InputField,
    MultiselectField,
    SelectField,
} from 'components/formFields'
import ProjectFilterFormikContext from './ProjectFilterFormikContext'
import FilterIconButton from 'components/FilterIconButton'

const ProjectFilters = ({ activeFilters, myProfile, disabled }) => {
    const t = useTranslate()

    const { isContractor, isVerified } = useContext(CurrentUserContext)

    const { replaceFilterParams } = useQueryParams()

    const [open, setOpen] = useState(false)

    const emptyValues = {
        company: [],
        keyword: '',
        name: '',
        industryType: null,
        projectType: [],
        divingMode: [],
        fromDate: { after: null, before: null },
        country: [],
        region: [],
        locationDetail: '',
        locationType: [],
        positionPayRateItem: {
            position: null,
            fromPayRate: '',
            toPayRate: '',
        },
        positionPayRate: [],
        toDurationDays: { gte: '', lte: '' },
    }

    const initialValues = {
        company: activeFilters.company ?? emptyValues.company,
        name: activeFilters.name ?? emptyValues.name,
        keyword: activeFilters.keyword ?? emptyValues.keyword,
        industryType: activeFilters.industryType ?? emptyValues.industryType,
        projectType: activeFilters.projectType ?? emptyValues.projectType,
        divingMode: activeFilters.divingMode ?? emptyValues.divingMode,
        fromDate: activeFilters.fromDate ?? emptyValues.fromDate,
        country: activeFilters.country ?? emptyValues.country,
        region: activeFilters.region ?? emptyValues.region,
        locationDetail:
            activeFilters.locationDetail ?? emptyValues.locationDetail,
        locationType: activeFilters.locationType ?? emptyValues.locationType,
        positionPayRateItem: emptyValues.positionPayRateItem,
        positionPayRate:
            activeFilters.positionPayRate ?? emptyValues.positionPayRate,
        toDurationDays:
            activeFilters.toDurationDays ?? emptyValues.toDurationDays,
    }

    const validation = Yup.object({
        fromDate: Yup.object().shape({
            before: Yup.date()
                .nullable()
                .test('before', (value, { parent, createError }) => {
                    if (
                        getIsDateSameOrBefore(
                            value,
                            parent.after,
                            MOMENT_FORMATS.DATE_API
                        )
                    ) {
                        return createError({
                            message: t('form.error.toDateGreaterThanFromDate'),
                            path: 'fromDate.before',
                        })
                    }
                    return true
                }),
            after: Yup.date().nullable(),
        }),
        toDurationDays: validateGteLteValues(
            t,
            'form.error.toDurationGreaterThanFromDuration',
            'toDurationDays.lte'
        ),
        positionPayRateItem: Yup.object().shape({
            fromPayRate: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            toPayRate: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('toPayRate', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.fromPayRate)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'positionPayRateItem.toPayRate',
                        })
                    }
                    return true
                }),
        }),
    })

    const handleFilter = (
        { positionPayRateItem, ...filters },
        { setSubmitting }
    ) => {
        setSubmitting(true)
        replaceFilterParams(filters)
        setSubmitting(false)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue, setTouched) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
        setTouched({})
    }

    const hasValue = (value) => value || value === 0

    const canAddPayRate = (item, value) =>
        item.position &&
        !value.some((obj) => obj.position.name === item.position.name) &&
        (hasValue(item.fromPayRate) || hasValue(item.toPayRate))

    const renderPayRateItem = (item) => {
        return `${item.position.name} | 
        ${item.fromPayRate || 0}$ - 
        ${item.toPayRate || ''}${item.toPayRate ? '$' : '/'}`
    }

    const getPayRateError = ({ positionPayRate, positionPayRateItem }) => {
        if (
            positionPayRateItem.position &&
            positionPayRate.some(
                (item) => item.position.id === positionPayRateItem.position.id
            )
        ) {
            return t('form.error.positionAlreadyExists')
        }
        return ''
    }

    return (
        <Fragment>
            <FilterIconButton setOpen={setOpen} disabled={disabled} />
            {open && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleFilter}
                >
                    {({ values, setFieldValue, setTouched, isSubmitting }) => (
                        <Form>
                            <ProjectFilterFormikContext
                                emptyValues={emptyValues}
                            />
                            <Modal
                                open={open}
                                setOpen={setOpen}
                                closeOnClickOutside={false}
                                title="general.filterDiveProjects"
                                isSubmitting={isSubmitting}
                                values={values}
                                buttons={{
                                    middleBtn: {
                                        handleClick: () =>
                                            handleReset(
                                                values,
                                                setFieldValue,
                                                setTouched
                                            ),
                                    },
                                    nextBtn: {
                                        label: 'button.filter',
                                    },
                                }}
                            >
                                <div className="_wr">
                                    <div className="_w">
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('general.divingContractor')}
                                        </span>
                                        {!(myProfile && isContractor) && (
                                            <div className="_12 _m6">
                                                <MultiselectField
                                                    name="company"
                                                    label="form.label.divingContractor"
                                                    placeholder="form.placeholder.divingContractor"
                                                    entityType={
                                                        isVerified
                                                            ? ENTITIES.COMPANY
                                                            : null
                                                    }
                                                    disabled={!isVerified}
                                                    searchable
                                                />
                                            </div>
                                        )}
                                        <div className="_12 _m6">
                                            <InputField
                                                name="name"
                                                label="form.label.projectName"
                                                placeholder="form.placeholder.projectName"
                                            />
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t(
                                                'general.projectIndustryAndDiveInfo'
                                            )}
                                        </span>
                                        <div className="_12 _m6">
                                            <SelectField
                                                name="industryType"
                                                label="form.label.industryType"
                                                placeholder="form.placeholder.selectIndustryType"
                                                entityType={ENTITIES.INDUSTRY}
                                                searchable
                                            />
                                        </div>
                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="projectType"
                                                label="form.label.projectType"
                                                placeholder="form.placeholder.selectProjectType"
                                                entityType={
                                                    ENTITIES.PROJECT_INDUSTRY_TYPES
                                                }
                                                params={
                                                    values.industryType && {
                                                        'industries.id':
                                                            values.industryType
                                                                ?.id,
                                                    }
                                                }
                                                reload={values.industryType}
                                                disabled={!values.industryType}
                                                searchable
                                            />
                                        </div>
                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="divingMode"
                                                label="form.label.modeOfDive"
                                                placeholder="form.placeholder.selectModeOfDive"
                                                entityType={
                                                    ENTITIES.DIVING_MODE
                                                }
                                                searchable
                                            />
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('general.startDateRange')}
                                        </span>
                                        <div className="_12 _m6">
                                            <DateTimeField
                                                name="fromDate.after"
                                                label="form.label.from"
                                                maxDate={
                                                    values.fromDate.before
                                                        ? new Date(
                                                              values.fromDate.before
                                                          )
                                                        : null
                                                }
                                            />
                                        </div>
                                        <div className="_12 _m6 calendarMovedToRight">
                                            <DateTimeField
                                                name="fromDate.before"
                                                label="form.label.to"
                                                minDate={
                                                    values.fromDate.after
                                                        ? getTomorrowDate(
                                                              values.fromDate
                                                                  .after
                                                          )
                                                        : null
                                                }
                                            />
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('general.locationInfo')}
                                        </span>
                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="country"
                                                label="form.label.country"
                                                placeholder="form.placeholder.selectCountries"
                                                entityType={ENTITIES.COUNTRY}
                                                searchable
                                            />
                                        </div>
                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="region"
                                                label="form.label.geographicalRegion"
                                                placeholder="form.placeholder.selectRegions"
                                                entityType={ENTITIES.REGION}
                                                icon={ICONS.LOCATION}
                                                size={ICON_SIZE.SIZE20}
                                                searchable
                                            />
                                        </div>
                                        <div className="_12 _m6">
                                            <InputField
                                                name="locationDetail"
                                                icon={ICONS.LOCATION}
                                                size={ICON_SIZE.SIZE20}
                                                iconColor={COLORS.DARK_BLUE_60}
                                            />
                                        </div>
                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="locationType"
                                                label="form.label.locationType"
                                                placeholder="form.placeholder.selectLocationType"
                                                entityType={
                                                    ENTITIES.LOCATION_TYPE
                                                }
                                                searchable
                                            />
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('form.label.payRate')}
                                        </span>
                                        <div className="_12">
                                            <ArrayCustomGroupField
                                                name="positionPayRate"
                                                itemName="positionPayRateItem"
                                                unit="$"
                                                itemInitialValue={
                                                    emptyValues.positionPayRateItem
                                                }
                                                canAddItem={canAddPayRate}
                                                renderItem={renderPayRateItem}
                                            >
                                                <SelectField
                                                    name="positionPayRateItem.position"
                                                    label="form.label.position"
                                                    placeholder="form.placeholder.selectPosition"
                                                    entityType={
                                                        ENTITIES.DIVER_POSITION
                                                    }
                                                    customFieldError={getPayRateError(
                                                        values
                                                    )}
                                                    dropup
                                                />
                                                <InputField
                                                    name="positionPayRateItem.fromPayRate"
                                                    label="form.label.from"
                                                    placeholder="form.placeholder.rateFrom"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                />
                                                <InputField
                                                    name="positionPayRateItem.toPayRate"
                                                    label="form.label.to"
                                                    placeholder="form.placeholder.rateTo"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                />
                                            </ArrayCustomGroupField>
                                        </div>
                                        <Separator />
                                        <span className="-mb20 _12 a-bodyTextMedium">
                                            {t('general.projectDurationInDays')}
                                        </span>
                                        <div className="_6 first-field noPadding">
                                            <InputField
                                                name="toDurationDays.gte"
                                                label="form.label.from"
                                                placeholder="form.placeholder.durationFrom"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                        <div className="_6 second-field">
                                            <InputField
                                                name="toDurationDays.lte"
                                                label="form.label.to"
                                                placeholder="form.placeholder.durationTo"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {isSubmitting && <Loader />}
                            </Modal>
                        </Form>
                    )}
                </Formik>
            )}
        </Fragment>
    )
}

ProjectFilters.propTypes = {
    activeFilters: PropTypes.object,
    myProfile: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default ProjectFilters
