import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import ROUTES from 'constants/routes'

const Footer = () => {
    const t = useTranslate()

    const FOOTER_MENU_ITEMS = [
        {
            key: 'wiki',
            to: `${ROUTES.HELP_AND_INFO}`,
        },
        {
            key: 'termsOfService',
            to: 'https://skillndepth.com/terms-of-service',
        },
        {
            key: 'privacyPolicy',
            to: 'https://skillndepth.com/privacy-policy',
        },
    ]

    return (
        <footer className="m-footer">
            <div className="_wr">
                <div className="_w ">
                    <div className="_12 m-footer__policy">
                        <div className="a-captionsTextRegular">
                            Skill N Depth &copy; {new Date().getFullYear()} | v
                            1.1.0
                        </div>
                        <div className="m-footer__policy--links">
                            {FOOTER_MENU_ITEMS.map((item, index) => (
                                <Link
                                    to={item.to}
                                    key={index}
                                    className="a-captionsTextRegular"
                                    target="_blank"
                                >
                                    {t(`footer.${item.key}`)}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
