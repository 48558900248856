import React, { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import {
    createEntityService,
    deleteEntityService,
    editEntityService,
} from 'services/entity.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import useFetchData from 'hooks/useFetchData'

import { ALERT_TYPES, CHECKBOX } from 'constants/enums'
import ENTITIES from 'constants/entities'

import ToggleButtons from 'components/ToggleButtons'
import RejectionTemplate from './components/RejectionTemplateItem'
import Checkbox from 'components/Checkbox'
import Separator from 'components/Separator'
import RejectionForm from 'components/rejectionModal/components/RejectionForm'
import Modal from 'components/Modal'
import AddNew from 'screens/diver/DiverOnboarding/AddNew'

const RejectionModal = ({ diverInvite, setOpen, handleReject }) => {
    const t = useTranslate()
    const initialTemplate = {
        title: '',
        body: '',
    }

    const { setAlert } = useContext(AlertContext)
    const { currentCompany, currentUser } = useContext(CurrentUserContext)

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const handleCancel = () => closeConfirmationModal()

    const handleCancelButton = (message, handleConfirm) => {
        showConfirmationModal({
            message: message,
            title: 'button.cancel',
            handleConfirm: () => {
                handleConfirm()
                closeConfirmationModal()
            },
            handleCancel,
        })
    }

    const [reapply, setReapply] = useState(true)
    const [isTemplateResponse, setIsTemplateResponse] = useState(true)
    const [openTemplate, setOpenTemplate] = useState(null)
    const [editingTemplate, setEditingTemplate] = useState(null)
    const [creatingTemplate, setCreatingTemplate] = useState(initialTemplate)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [newItem, setNewItem] = useState(null)
    const [newTemplate, setNewTemplate] = useState(null)
    const [editingNewChanges, setEditingNewChanges] = useState(false)

    const {
        data: rejectionTemplates,
        fetchData: fetchTemplates,
    } = useFetchData(ENTITIES.REJECTION_TEMPLATE, {}, true, false)

    useEffect(() => {
        if (!newItem) {
            return
        }

        setSelectedTemplate(rejectionTemplates.find((t) => t.id === newItem.id))
        setNewItem(null)
    }, [rejectionTemplates])

    const editTemplate = (template) => {
        setEditingTemplate(template)
    }

    const chooseTemplate = (template, event) => {
        //ToDO cancel bubble, prevent default
        if (selectedTemplate && template.id === selectedTemplate.id) {
            setSelectedTemplate(null)
        } else {
            setSelectedTemplate(template)
        }
    }

    const validateOnSend = () => {
        // if(isTemplateResponse && !selectedTemplate) {
        //     setAlert(t('form.error.rejectionTemplateNotSelected'), ALERT_TYPES.ERROR)
        //     return false;
        // }

        if (
            !isTemplateResponse &&
            (!creatingTemplate ||
                !creatingTemplate.title ||
                !creatingTemplate.body)
        ) {
            setAlert(
                t('form.error.rejectionTemplateFieldsEmpty'),
                ALERT_TYPES.ERROR
            )
            return false
        }

        return true
    }

    const sendRejection = () => {
        if (!validateOnSend()) {
            return
        }

        const obj = {
            inviteRejected: true,
            blockReapply: !reapply,
            positionRejections: [
                {
                    data: {
                        type: ENTITIES.POSITION_REJECTION,
                        attributes: {
                            title:
                                isTemplateResponse && selectedTemplate
                                    ? selectedTemplate.title
                                    : creatingTemplate.title || '',
                            body:
                                isTemplateResponse && selectedTemplate
                                    ? selectedTemplate.body
                                    : creatingTemplate.body || '',
                        },
                        relationships: {
                            user: {
                                data: {
                                    id: diverInvite.workerId,
                                    type: ENTITIES.USER,
                                },
                            },
                            ...(isTemplateResponse &&
                                selectedTemplate && {
                                    rejectionTemplate: {
                                        data: {
                                            id: selectedTemplate.id,
                                            type: ENTITIES.REJECTION_TEMPLATE,
                                        },
                                    },
                                }),
                        },
                    },
                },
            ],
        }

        handleReject(
            diverInvite.id,
            obj,
            'general.successfullyRejected',
            'form.error.canNotRejectApplication'
        )
    }

    const saveTemplate = async (values) => {
        if (!values.id) {
            const { data } = await createEntityService(
                ENTITIES.REJECTION_TEMPLATE,
                {
                    ...values,
                    company: {
                        id: currentCompany.id,
                        entityType: ENTITIES.COMPANY,
                    },
                }
            )
            setAlert(t('message.templateSaved'), ALERT_TYPES.SUCCESS)
            fetchTemplates()
            setNewItem(data)
            setIsTemplateResponse(true)
            setEditingTemplate(null)
            setNewTemplate(false)
        } else {
            const { data } = await editEntityService(
                ENTITIES.REJECTION_TEMPLATE,
                editingTemplate.id,
                values,
                true
            )
            setAlert(t('message.templateSaved'), ALERT_TYPES.SUCCESS)
            fetchTemplates()
            setNewItem(data)
            setIsTemplateResponse(true)
            setEditingTemplate(null)
        }
    }

    const deleteTemplate = (values) => {
        deleteEntityService(
            ENTITIES.REJECTION_TEMPLATE,
            editingTemplate.id,
            null
        ).then((response) => {
            if (editingTemplate?.id === selectedTemplate?.id) {
                setSelectedTemplate(null)
            }
            setEditingTemplate(null)
            fetchTemplates()
            setAlert(t('message.templateDeleted'), ALERT_TYPES.SUCCESS)
        })
    }

    const handleChangeToNewTemplate = () => {
        setIsTemplateResponse(true)
        setCreatingTemplate(initialTemplate)
        setNewTemplate(false)
    }

    const changeToTemplateResponse = () => {
        creatingTemplate.title === '' && creatingTemplate.body === ''
            ? handleChangeToNewTemplate()
            : handleCancelButton(
                  'message.areYouSureCancelCreatingMessage',
                  () => {
                      handleChangeToNewTemplate()
                  }
              )
    }

    const handleChangeToNewMessage = () => {
        setNewTemplate(false)
        setIsTemplateResponse(false)
        setEditingTemplate(null)
        setCreatingTemplate(initialTemplate)
    }

    const changeToNewMessageResponse = () => {
        if (
            (newTemplate &&
                (creatingTemplate.title !== '' ||
                    creatingTemplate.body !== '')) ||
            (editingTemplate && editingNewChanges)
        ) {
            handleCancelButton(
                editingTemplate
                    ? 'message.areYouSureCancelEditingTemplate'
                    : 'message.areYouSureCancelCreatingTemplate',
                () => {
                    handleChangeToNewMessage()
                }
            )
        } else {
            handleChangeToNewMessage()
        }
    }

    const buttons = [
        {
            label: 'form.label.templates',
            clickHandler: changeToTemplateResponse,
        },
        {
            label: 'form.label.newMessage',
            clickHandler: changeToNewMessageResponse,
        },
    ]

    return (
        <Modal
            setOpen={setOpen}
            closeOnClickOutside={false}
            type="-rejectionModal"
            title="form.label.createTemplateHeading"
            buttons={{
                nextBtn: {
                    label: `${
                        selectedTemplate || !isTemplateResponse
                            ? 'button.send'
                            : 'button.sendWithoutMessage'
                    }`,
                    handleClick: sendRejection,
                },
            }}
        >
            <p className="_12 -mb30 a-lightText -opacity-60 text-align-center a-bodyTextRegular">
                {t('form.label.createTemplateInfo')}
            </p>
            <div className="_12 -mb20">
                <ToggleButtons
                    buttons={buttons}
                    activeButtonIndex={isTemplateResponse ? 0 : 1}
                />
            </div>
            {isTemplateResponse && !editingTemplate && !newTemplate && (
                <div className="_12">
                    <div className="column">
                        <p className="_12 a-captionsTextRegular a-lightText -opacity-60 -mb10 text-align-center">
                            {t('form.label.selectTemplate')}
                        </p>
                        {rejectionTemplates.map((template) => {
                            return (
                                <RejectionTemplate
                                    key={template.id}
                                    template={template}
                                    selectedTemplate={selectedTemplate}
                                    chooseTemplate={chooseTemplate}
                                    openTemplate={openTemplate}
                                    setOpenTemplate={setOpenTemplate}
                                    editTemplate={editTemplate}
                                />
                            )
                        })}
                        <AddNew
                            label="button.createNewTemplate"
                            handleAction={() => {
                                setNewTemplate(true)
                                setEditingTemplate(false)
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="_12">
                {!isTemplateResponse && !newTemplate && (
                    <div className="-mb15 fullWidth">
                        <RejectionForm
                            setCreatingTemplate={setCreatingTemplate}
                            saveTemplate={saveTemplate}
                            cancelTemplate={() =>
                                creatingTemplate.title === '' &&
                                creatingTemplate.body === ''
                                    ? setIsTemplateResponse(true)
                                    : handleCancelButton(
                                          'message.areYouSureCancelCreatingMessage',
                                          () => setIsTemplateResponse(true)
                                      )
                            }
                        />
                    </div>
                )}

                {editingTemplate && (
                    <RejectionForm
                        template={editingTemplate}
                        saveTemplate={saveTemplate}
                        deleteTemplate={deleteTemplate}
                        cancelTemplate={() =>
                            !editingNewChanges
                                ? setEditingTemplate(null)
                                : handleCancelButton(
                                      'message.areYouSureCancelEditingTemplate',
                                      () => setEditingTemplate(null)
                                  )
                        }
                        setEditingNewChanges={setEditingNewChanges}
                    />
                )}

                {newTemplate && (
                    <RejectionForm
                        setCreatingTemplate={setCreatingTemplate}
                        saveTemplate={saveTemplate}
                        cancelTemplate={() =>
                            creatingTemplate.title === '' &&
                            creatingTemplate.body === ''
                                ? setNewTemplate(false)
                                : handleCancelButton(
                                      'message.areYouSureCancelCreatingTemplate',
                                      () => setNewTemplate(false)
                                  )
                        }
                        newTemplate
                    />
                )}
            </div>
            <Separator />
            <div className=" -mb20 -mt5">
                <div className="_12 centered-checkbox">
                    <Checkbox
                        name={'reapply'}
                        label={'form.label.reapply'}
                        value={reapply}
                        onChange={(e) => {
                            setReapply(e.target.checked)
                        }}
                        translate={true}
                        type={CHECKBOX.CHECKED}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default RejectionModal
