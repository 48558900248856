import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'

import { InputField } from 'components/formFields'
import Modal from 'components/Modal'

const EditTitleModal = ({ setOpen, formRef }) => {
    const {
        current: { values, setFieldValue },
    } = formRef

    const initialValues = {
        title: values.currentTitle,
    }

    const handleSubmit = ({ title }) => {
        setFieldValue('currentTitle', title)
        setOpen(false)
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {() => (
                <Form>
                    <Modal
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        type="-confirmModal -smallModal"
                        title="general.editTitle"
                        buttons={{
                            nextBtn: {
                                label: 'button.saveChanges',
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className=" _w">
                                <div className="_12">
                                    <InputField name="title" />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

EditTitleModal.propTypes = {
    setOpen: PropTypes.func,
    formRef: PropTypes.object.isRequired,
}

export default EditTitleModal
