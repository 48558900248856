import React from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { editEntityService } from 'services/entity.service'

import { ACCESS_CONTROL_STATUSES, SELECT_VALUE_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Dropdown from 'components/Dropdown'
import RadioGroup from 'components/RadioGroup'
import Icon from 'components/Icon'

const { NEW, REJECTED, GRANTED_WITHOUT_FILES, GRANTED_WITH_FILES } =
    ACCESS_CONTROL_STATUSES

const ChangeAccessAction = ({ open, setOpen, item, fetchData, noFiles }) => {
    const t = useTranslate()

    const { id, status } = item

    const handleChange = async (value) => {
        try {
            await editEntityService(ENTITIES.DATA_ACCESS_REQUEST, id, {
                status: value,
            })
            setOpen(false)
            fetchData()
        } catch (error) {
            console.log('error >> ', error)
        }
    }

    const newRequestOptions = [
        { id: GRANTED_WITHOUT_FILES.id, name: 'Without files' },
        { id: GRANTED_WITH_FILES.id, name: 'With files' },
    ]

    const allRequestOptions = [
        ...newRequestOptions,
        {
            id: REJECTED.id,
            name: 'Withdraw access',
        },
    ]

    const statusTitle =
        status === NEW.id || status === REJECTED.id
            ? t('general.grantAccess')
            : !noFiles
            ? t('general.changeAccess')
            : t('general.denyAccess')

    const statusIcon =
        status === NEW.id || status === REJECTED.id
            ? `${ICONS.GRANT_ACCESS}`
            : !noFiles
            ? `${ICONS.CHANGE_ACCESS}`
            : `${ICONS.DENY_ACCESS}`

    return (
        <div className="a-access">
            <div
                className={`a-access__select`}
                onClick={
                    !noFiles
                        ? () => setOpen(!open)
                        : () =>
                              handleChange(
                                  statusTitle === t('general.grantAccess')
                                      ? GRANTED_WITH_FILES.id
                                      : REJECTED.id
                              )
                }
            >
                <Icon name={statusIcon} />
                {statusTitle}
                {!noFiles && <span className="m-selectGroup__arrow"></span>}
            </div>
            {!noFiles && (
                <Dropdown open={open}>
                    <RadioGroup
                        name={`access-${id}`}
                        showLabel={false}
                        defaultOptions={
                            status === NEW.id || status === REJECTED.id
                                ? newRequestOptions
                                : allRequestOptions
                        }
                        value={status}
                        setValue={handleChange}
                        valueType={SELECT_VALUE_TYPE.STRING}
                        reload={status}
                    />
                </Dropdown>
            )}
        </div>
    )
}

ChangeAccessAction.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    item: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    noFiles: PropTypes.bool,
}

export default clickOutsideHOC(ChangeAccessAction)
