import { POSITIONS } from 'constants/enums'
import ICONS from 'constants/icons'

const getPositionImage = (position) => {
    switch (position) {
        case POSITIONS.DIVER.name:
            return ICONS.POSITION_DIVER
        case POSITIONS.DIVING_SUPERVISOR.name:
            return ICONS.POSITION_DIVING_SUPERVISOR
        case POSITIONS.DIVING_MEDIC.name:
            return ICONS.POSITION_DIVING_MEDIC
        case POSITIONS.EQUIPMENT_TECHNICIAN.name:
            return ICONS.POSITION_EQUIPMENT_TECHNICIAN
        case POSITIONS.LIFE_SUPPORT.name:
            return ICONS.POSITION_LIFE_SUPPORT
        default:
            break
    }
}

export { getPositionImage }
