import { Formik, Form } from 'formik'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { editEntityService } from 'services/entity.service'

import { ALERT_TYPES } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'

import { ProfilePictureUploadField } from 'components/formFields'
import Loader from 'components/Loader'

const DiverPhoto = ({ userData }) => {
    const navigate = useNavigate()

    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { userId, fetchCurrentUser } = useContext(CurrentUserContext)

    const initialValues = {
        avatar: userData.avatar ?? null,
    }

    const handleSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await editEntityService(ENTITIES.USER, userId, {
                ...userData,
                ...formData,
                finishedSignUp: true,
            })
            await fetchCurrentUser()
            navigate(ROUTES.HOME)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <div className="m-form -register -mb20">
            <h1 className="-title -mb20">{t('general.addYourProfilePhoto')}</h1>
            <span className="a-bodyTextRegular a-lightText -mb50 a-centeredText">
                {t('general.addingPhoto')}
            </span>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ values, isSubmitting, ...formProps }) => (
                    <Form>
                        <ProfilePictureUploadField
                            name="avatar"
                            label="form.label.avatarPath"
                            multipleUpload={false}
                            signUp={true}
                            userName={
                                userData.firstName + ' ' + userData.lastName
                            }
                            positions={userData.diverPositions}
                            location={userData.country}
                            handleSubmit={handleSubmit}
                            disabled={isSubmitting}
                            values={values}
                            formProps={formProps}
                        />
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

DiverPhoto.propTypes = {
    userData: PropTypes.object.isRequired,
}

export default DiverPhoto
