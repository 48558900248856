import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

const Note = ({ note }) => {
    const t = useTranslate()

    return (
        <div className="a-note fullWidth -mb10">
            <p className="a-note__text a-captionsTextRegular">
                <span className="a-captionsTextSemiBold">
                    {t('notes.note')}{' '}
                </span>
                {t(note)}
            </p>
        </div>
    )
}

Note.propTypes = {
    note: PropTypes.string,
}

Note.defaultProps = {
    note: 'notes.featureUnderDevelopment',
}

export default Note
