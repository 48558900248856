import ROUTES from './routes'
import { TAB } from 'components/diveTeamMembers/constants/tabs'

export const SEARCH_DELAY = 10

export const DEFAULT_SEARCH_FILED = 'name'
export const PROJECT_SEARCH_FILED = 'keyword'
export const JOBS_SEARCH_FILED = 'keyword'

export const YES_NO_OPTIONS = [
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
]

export const YES_NO_BOOLEAN_OPTIONS = [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
]

export const UNITS_OF_MEASUREMENT = [
    { id: 1, name: 'Imperial' },
    { id: 0, name: 'Metric' },
]

export const DATA_ACCESS_TYPES = [
    { id: false, name: 'Selective access' },
    { id: true, name: 'Public access' },
]

export const RATE_OPTIONS = [1, 2, 3, 4, 5]

export const ACTION = {
    NAVIGATE: 'navigate',
    LOGOUT: 'logout',
}

export const ROLE_OPTIONS = [
    { id: 1, name: 'Diver' },
    { id: 2, name: 'Diving supervisor' },
]

export const PROTECTED_ROUTES = [
    `${ROUTES.PROJECT}${ROUTES.CREATE}`,
    `${ROUTES.PROJECT}${ROUTES.EDIT}`,
    `${ROUTES.MY_DIVE_PROJECTS}${ROUTES.CREATE}`,
    `${ROUTES.MY_DIVE_PROJECTS}${ROUTES.EDIT}`,
]

export const libraries = ['marker', 'places']

export const EXPERIENCE_OPTIONS = [
    { id: 0, name: 'Total years of experience' },
    { id: 1, name: 'Experience in specific dive mode' },
]

export const GOOGLE_MAPS_API_KEY = 'AIzaSyBN6YPgKEuMFrGEcXkP-6ol9CGCAjT_Ohk'

export const MAP_ID = '8ce7d185c03e3a67'

export const DEFAULT_TEAM_CHAT_IMAGE =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none'%3E%3Ccircle cx='20' cy='20' r='20' fill='%23F3F4F5'/%3E%3Ccircle cx='3.404' cy='3.404' r='3.404' stroke='%23A2A5B1' stroke-width='1.5' transform='matrix(-1 0 0 1 23.617 13)'/%3E%3Cpath stroke='%23A2A5B1' stroke-width='1.5' d='M14.255 24.86c0-.733.46-1.386 1.15-1.632a14.294 14.294 0 0 1 9.615 0c.69.246 1.15.9 1.15 1.631v1.12a1.66 1.66 0 0 1-1.895 1.644l-.334-.048a26.363 26.363 0 0 0-7.457 0l-.333.048a1.66 1.66 0 0 1-1.896-1.644v-1.12Z'/%3E%3Cpath stroke='%23A2A5B1' stroke-linecap='round' stroke-width='1.5' d='M25.32 19.903a2.675 2.675 0 1 0 0-5.35M28.249 26.005l.262.037A1.305 1.305 0 0 0 30 24.751v-.88c0-.575-.362-1.088-.904-1.282a11.23 11.23 0 0 0-1.65-.45M14.68 19.903a2.675 2.675 0 1 1 0-5.35M11.751 26.005l-.262.037A1.305 1.305 0 0 1 10 24.751v-.88c0-.575.362-1.088.903-1.282a11.23 11.23 0 0 1 1.65-.45'/%3E%3C/svg%3E"

export const VERIFICATION_LEVEL_COLORS = [
    'orangeWithBcg',
    'orangeWithBcg',
    'purpleWithBcg',
    'blueWithBcg',
    'greenWithBcg',
    'greenWithBcg',
]

export const PROFILE_STEPS = [
    {
        key: 'personalInfo',
        message: 'general.addMoreToPersonalInfo',
        additionalMessage: 'general.completePersonalInfo',
        route: ROUTES.DIVER_PROFILE,
    },
    {
        key: 'medicalInfo',
        message: 'general.addMoreToMedicalInfo',
        additionalMessage: 'general.completeMedicalInfo',
        route: ROUTES.DIVER_PROFILE,
        params: { tab: TAB.MEDICAL_INFO },
    },
    {
        key: 'travelDocument',
        message: 'general.addPersonalId',
        additionalMessage: 'general.completeTravelDocument',
        route: ROUTES.DIVER_PROFILE,
        params: { tab: TAB.TRAVEL_DOCUMENTATION },
    },
    {
        key: 'trainingRecord',
        message: 'general.addEducationAndTrainingRecord',
        route: ROUTES.DIVER_PROFILE,
        params: { tab: TAB.TRAINING_RECORD },
        additionalMessage: 'general.completeTrainingRecord',
    },
    {
        key: 'skill',
        message: 'general.addExperienceAndSkills',
        route: ROUTES.DIVER_PROFILE,
        params: { tab: TAB.SKILLS },
        additionalMessage: 'general.completeSkills',
    },
]
