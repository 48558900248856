import { POSITIONS } from 'constants/enums'

const renderRoleNames = (roleNumber) => {
    return roleNumber
        .map((item) => {
            const roleKey = Object.keys(POSITIONS).find(
                (key) => POSITIONS[key].id === item
            )

            return POSITIONS[roleKey].name
        })
        .join(', ')
}

export { renderRoleNames }
