import React, { useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { DiveRecordContext } from './contexts/DiveRecordContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'

import useFetchDataById from 'hooks/useFetchDataById'
import useSaveByStep from './hooks/useSaveByStep'

import { persistEntityService } from 'services/entity.service'

import StepButtons from 'components/StepButtons'
import Loader from 'components/Loader'
import Separator from 'components/Separator'
import { MultiselectField } from 'components/formFields'
import FocusError from '../../../components/FocusError'

const WorkPerformedRecord = () => {
    const t = useTranslate()
    const { diveRecordId } = useParams()
    const formRef = useRef()

    const { setAlert } = useContext(AlertContext)
    const {
        diveRecord,
        isSupervisorDiveRecord,
        handlePostSaveAction,
        goToStepRef,
    } = useContext(DiveRecordContext)

    const { data, isLoading } = useFetchDataById(
        ENTITIES.DIVE_RECORD,
        diveRecordId,
        {
            include: [
                'diveRecordWorkPerformed',
                'diveRecordWorkPerformed.tasks',
                'diveRecordWorkPerformed.objects',
                'diveRecordWorkPerformed.activities',
                'diveRecordWorkPerformed.tools',
                'diveRecordWorkPerformed.supervisorTasks',
            ],
        }
    )

    const initialData = data?.diveRecordWorkPerformed ?? null

    const handleSave = async (
        formData,
        formActions,
        complete = true,
        goBack = false
    ) => {
        try {
            await formRef.current.validateForm()
            const isValid = !Object.keys(formRef.current.errors).length

            await persistEntityService(
                ENTITIES.DIVE_RECORD_WORK_PERFORMED,
                {
                    ...formData,
                    diveRecord: diveRecord.data,
                    complete: complete || isValid,
                },
                diveRecord.data?.diveRecordWorkPerformed?.id
            )

            handlePostSaveAction(complete, goBack, goToStepRef.current)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    useSaveByStep(formRef, handleSave)

    if (!data) return null

    const initialValues = {
        supervisorTasks: initialData?.supervisorTasks ?? [],
        tasks: initialData?.tasks ?? [],
        objects: initialData?.objects ?? [],
        activities: initialData?.activities ?? [],
        tools: initialData?.tools ?? [],
    }

    const validation = Yup.object().shape({
        supervisorTasks: Yup.array().notRequired(),
        tasks: Yup.array().notRequired(),
        objects: Yup.array().notRequired(),
        activities: Yup.array().notRequired(),
        tools: Yup.array().notRequired(),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSave}
            innerRef={formRef}
        >
            {({ handleSubmit, values, isSubmitting }) => (
                <Form>
                    <FocusError />
                    <div className="_wr -contentElements -diveRecordContent">
                        {isSupervisorDiveRecord && (
                            <div>
                                <div className="_w">
                                    <span className="-mb20 -mt5 _12 a-bodyTextRegular">
                                        {t('general.supervisorTasks')}
                                    </span>
                                </div>
                                <div className="_w">
                                    <div className="_12 _m6 _l4">
                                        <MultiselectField
                                            name="supervisorTasks"
                                            label="form.label.tasks"
                                            placeholder="form.placeholder.tasks"
                                            entityType={
                                                ENTITIES.DIVE_RECORD_SUPERVISOR_TASK
                                            }
                                            searchable
                                            createNew
                                        />
                                    </div>
                                    <Separator />
                                </div>
                            </div>
                        )}
                        <div className="_w">
                            <span className="-mb20 -mt5 _12 a-bodyTextRegular">
                                {t(
                                    isSupervisorDiveRecord
                                        ? 'general.diverTasks'
                                        : 'general.tasks'
                                )}
                            </span>
                        </div>
                        <div className="_w">
                            <div className="_12 _m6 _l4">
                                <MultiselectField
                                    name="tasks"
                                    entityType={ENTITIES.DIVE_RECORD_WORK_TASK}
                                    searchable
                                    createNew
                                />
                            </div>

                            <Separator />
                        </div>

                        <div className="_w">
                            <span className="-mb20 _12 a-bodyTextRegular">
                                {t('general.objects')}
                            </span>
                        </div>
                        <div className="_w">
                            <div className="_12 _m6 _l4">
                                <MultiselectField
                                    name="objects"
                                    entityType={
                                        ENTITIES.DIVE_RECORD_WORK_OBJECT
                                    }
                                    searchable
                                    createNew
                                />
                            </div>

                            <Separator />
                        </div>

                        <div className="_w">
                            <span className="-mb20 _12 a-bodyTextRegular">
                                {t('general.activities')}
                            </span>
                        </div>
                        <div className="_w">
                            <div className="_12 _m6 _l4">
                                <MultiselectField
                                    name="activities"
                                    entityType={
                                        ENTITIES.DIVE_RECORD_WORK_ACTIVITY
                                    }
                                    searchable
                                    createNew
                                    dropup
                                />
                            </div>

                            <Separator />
                        </div>

                        <div className="_w">
                            <span className="-mb20 _12 a-bodyTextRegular">
                                {t('general.tools')}
                            </span>
                        </div>
                        <div className="_w">
                            <div className="_12 _m6 _l4">
                                <MultiselectField
                                    name="tools"
                                    entityType={ENTITIES.DIVE_RECORD_WORK_TOOL}
                                    searchable
                                    createNew
                                    dropup
                                />
                            </div>
                        </div>
                    </div>
                    <StepButtons
                        skipLabel="button.saveAndContinueLater"
                        handleNext={handleSubmit}
                        handleSkip={() => handleSave(values, null, false)}
                        handleBack={() => handleSave(values, null, false, true)}
                        canSkip
                        disableNext={isSubmitting}
                    />
                    {(isSubmitting || isLoading) && <Loader />}
                </Form>
            )}
        </Formik>
    )
}

export default WorkPerformedRecord
