import PropTypes from 'prop-types'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { formatDate } from 'services/moment.service'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { getProjectStatusColor } from 'utils/dataFormatters'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { BUTTON_STATUS, INFO_VALUE_TYPE, POSITIONS } from 'constants/enums'

import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import Button from 'components/Button'
import CreateDiveRecordModal from 'screens/common/CreateDiveRecordModal'
import MapView from 'components/MapView'
import ROUTES from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import CardHeader from 'components/card/CardHeader'

const ProjectContainer = ({
    data,
    handleDelete,
    canCreateDiveRecord,
    positionOnCurrentProject,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { isDiver, userId } = useContext(CurrentUserContext)

    const {
        id,
        name,
        projectStatus,
        company,
        country,
        region,
        locationDetail,
        bodyOfWater,
        altitude,
        countryOfMobilization,
        nameOfVessel,
        fromDate,
        toDurationDays,
        locationType,
        divingMode,
        toDepth,
        projectPositions,
        projectIndustryType,
        projectScope,
        creator,
        unitImperial,
        geoLat,
        geoLong,
    } = data

    const formattedPositions = (projectPositions || []).map(
        (position) => position?.diverPosition?.name
    )

    const unitOfMeasurementLabel = unitImperial
        ? t('form.label.ft')
        : t('form.label.m')

    const cantViewRecords = () => {
        if (canCreateDiveRecord) {
            return true
        }

        if (
            isDiver &&
            positionOnCurrentProject === POSITIONS.DIVING_SUPERVISOR.name
        ) {
            return true
        }

        return !isDiver && creator.id === userId
    }

    return (
        <CardContainer>
            <CardHeader
                title={name}
                largeTitle
                statuses={[
                    {
                        type: 'status',
                        name: [projectStatus?.name],
                        color: getProjectStatusColor(projectStatus?.code),
                    },
                ]}
                item={data}
                actions={
                    isDiver || creator.id !== userId
                        ? []
                        : [
                              {
                                  handleAction: handleDelete,
                                  icon: ICONS.DELETE,
                                  iconColor: COLORS.RED,
                                  tooltip: 'button.deleteProject',
                              },
                          ]
                }
            />
            <Info
                label="form.label.divingContractor"
                value={company.name}
                companyID={company?.profileHash}
                valueType={INFO_VALUE_TYPE.CONTRACTOR_LINK}
                withBorder
                withSpaceBetween
            />
            <Info
                label="form.label.location"
                value={country.name}
                withBorder
                withSpaceBetween
            />

            {region && (
                <Info
                    label="form.label.geographicalRegion"
                    value={region.name}
                    withBorder
                    withSpaceBetween
                />
            )}

            {locationDetail && (
                <Info
                    label="form.label.locationDetail"
                    value={locationDetail}
                    withBorder
                    withSpaceBetween
                />
            )}
            <Info
                label="form.label.countryOfMobilization"
                value={countryOfMobilization.name}
                withBorder
                withSpaceBetween
            />
            {nameOfVessel && (
                <Info
                    label="form.label.divingVessel"
                    value={nameOfVessel}
                    withBorder
                    withSpaceBetween
                />
            )}
            <Info
                label="form.label.projectStart"
                value={formatDate(fromDate)}
                withBorder
                withSpaceBetween
            />
            {toDurationDays && (
                <Info
                    label="form.label.expectedDuration"
                    value={toDurationDays}
                    withBorder
                    withSpaceBetween
                />
            )}
            <Info
                label="form.label.locationType"
                value={locationType.name}
                color="green"
                withBorder
                withSpaceBetween
            />
            <Info
                label="form.label.divingMode"
                value={divingMode.name}
                color="orange"
                withBorder
                withSpaceBetween
            />

            {bodyOfWater && (
                <Info
                    label="form.label.bodyOfWater"
                    value={bodyOfWater.name}
                    withBorder
                    withSpaceBetween
                />
            )}

            <Info
                label="form.label.altitude"
                value={altitude + unitOfMeasurementLabel}
                withBorder
                withSpaceBetween
            />
            {toDepth && (
                <Info
                    label="form.label.waterDepths"
                    value={toDepth ? toDepth + ' m' : ''}
                    withBorder
                    withSpaceBetween
                />
            )}
            <Info
                label="form.label.teamPositions"
                value={formattedPositions}
                separator={formattedPositions.length > 1}
                withBorder
                withSpaceBetween
            />
            <Info
                label="form.label.projectType"
                value={projectIndustryType?.name}
                withBorder
                withSpaceBetween
            />
            <div className="-mb10">
                <Info
                    label="form.label.scopeOfWork"
                    value={projectScope}
                    column
                    descriptionTextWithoutLimit
                />
            </div>
            <div className="aligned-center">
                <MapView geoLat={Number(geoLat)} geoLong={Number(geoLong)} />
            </div>
        </CardContainer>
    )
}

ProjectContainer.propTypes = {
    data: PropTypes.object,
    handleDelete: PropTypes.func,
    canCreateDiveRecord: PropTypes.bool,
    isSupervisorOnCurrentProject: PropTypes.bool,
}

export default ProjectContainer
