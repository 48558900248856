import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useCanLeavePage from 'hooks/useCanLeavePage'

import handleLogout from 'utils/logoutHelper'

import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { ACTION } from 'constants/constants'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'
import clickOutsideHOC from 'components/wrappers/clickOutsideHOC'
import Avatar from './Avatar'

const AvatarDropdown = ({ open }) => {
    const t = useTranslate()

    const { canLeavePage, handleShowModal } = useCanLeavePage()

    const {
        currentUser,
        setCurrentUser,
        userId,
        isDiver,
        currentCompany,
        setCurrentCompany,
    } = useContext(CurrentUserContext)

    const handleOpenModal = (action, route) => {
        if (!canLeavePage && action === ACTION.NAVIGATE) {
            handleShowModal(route)
        } else if (!canLeavePage) {
            handleShowModal(null)
        }
    }

    if (!currentUser || (!isDiver && !currentCompany)) return null
    return (
        <div className="m-avatar">
            <Avatar
                firstName={currentUser.firstName}
                lastName={currentUser.lastName}
                avatar={
                    currentUser?.thumbnails.md || currentCompany?.thumbnails.md
                }
            />
            <i className={`a-chevron ${open ? '-up' : '-down'}`} />
            <div className={`m-avatar__popup ${open ? '-open' : ''}`}>
                <Link
                    className="m-avatar__item a-mediumText"
                    to={
                        canLeavePage
                            ? isDiver
                                ? `${ROUTES.DIVER_PROFILE}/${currentUser.profileHash}`
                                : `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${currentCompany.profileHash}`
                            : undefined
                    }
                    onClick={() =>
                        canLeavePage
                            ? undefined
                            : handleOpenModal(
                                  ACTION.NAVIGATE,
                                  isDiver
                                      ? `${ROUTES.DIVER_PROFILE}/${currentUser.profileHash}`
                                      : `${ROUTES.DIVING_CONTRACTOR_PROFILE}/${currentCompany.profileHash}`
                              )
                    }
                >
                    <Icon
                        name={ICONS.USER}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE22}
                    />
                    {t('button.profile')}
                </Link>

                {/* NOTE:: for now settings are available only for the diver */}
                {isDiver && (
                    <Link
                        className="m-avatar__item a-mediumText"
                        to={ROUTES.SETTINGS}
                    >
                        <Icon
                            name={ICONS.SETTINGS}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE22}
                        />
                        {t('button.settingsAndPrivacy')}
                    </Link>
                )}
                {isDiver && (
                    <Link
                        className="m-avatar__item a-mediumText"
                        to={`${ROUTES.SETTINGS}/${ROUTES.ACCOUNT_MANAGEMENT}`}
                    >
                        <Icon
                            name={ICONS.ACCOUNT_MANAGEMENT}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE24}
                        />
                        {t('button.accountManagement')}
                    </Link>
                )}
                <Link
                    className="m-avatar__item a-mediumText"
                    to={canLeavePage ? ROUTES.HELP_AND_INFO : undefined}
                    onClick={() =>
                        canLeavePage
                            ? undefined
                            : handleOpenModal(
                                  ACTION.NAVIGATE,
                                  ROUTES.HELP_AND_INFO
                              )
                    }
                >
                    <Icon
                        name={ICONS.HELP_AND_INFO}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE22}
                    />
                    {t('button.helpAndInfo')}
                </Link>
                <Link
                    className="m-avatar__item -logout a-mediumText"
                    to={canLeavePage ? '#' : undefined}
                    onClick={() =>
                        canLeavePage
                            ? handleLogout(setCurrentUser, setCurrentCompany)
                            : handleOpenModal(ACTION.LOGOUT)
                    }
                >
                    <Icon
                        name={ICONS.LOGOUT}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE22}
                    />
                    {t('button.logout')}
                </Link>
            </div>
        </div>
    )
}

AvatarDropdown.propTypes = {
    open: PropTypes.bool.isRequired,
}

export default clickOutsideHOC(AvatarDropdown, true)
